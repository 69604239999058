import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import logo from "../../assets/images/logo.svg";
import email_icon from "../../assets/images/icons/email.svg";
import shop_icon from "../../assets/images/icons/shop_icon.svg";
import user_icon from "../../assets/images/icons/user_icon.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import document_icon from "../../assets/images/icons/document_icon.svg";
import tele_icon from "../../assets/images/icons/tele_icon.svg";
import location_icon from "../../assets/images/icons/location_icon.svg";
import login from "../../assets/images/login.svg";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { DarkButton } from "../commonComponents/commonComp";
import {
  simpleGetCallnoTokan,
  simplePostAuthCallNoTokan,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const ClinicInfo = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(AppContext);
  console.log("shop info user data =>", userData);
  const [validated, setValidated] = useState(false);
  const [shopTypes, setShopTypes] = useState([]);
  const [ShopInfo, setShopInfo] = useState({
    pharmacy_id: localStorage.getItem("pharmacy_id"),
    shop_name: "",
    shop_address: "",
    type_of_pharmacy_shop: null,
    shop_license_number: null,
    longitude: "",
    latitude: "",
  });
  console.log("ShopInfo,", ShopInfo)
  const changeEveValue = (e) => {
    setShopInfo({
      ...ShopInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      addShopDetails(event);
    }
    setValidated(true);
  };

  const addShopDetails = (event) => {
    event.preventDefault();
    simplePostAuthCallNoTokan(
      ApiConfig.ADD_SHOP_DETAILS,
      JSON.stringify(ShopInfo)
    )
      .then((res) => {
        if (res.success === true) {
          localStorage.setItem("pharmacy_shop_id", res.data.pharmacy_shop_id);
          localStorage.setItem(
            "pharmacy_shop_name",
            res.data.pharmacy_shop_name
          );
          localStorage.setItem(
            "type_of_pharmacy_shop_id",
            res.data.type_of_pharmacy_shop_id
          );
          localStorage.setItem(
            "pharmacy_shop_address",
            res.data.pharmacy_shop_address
          );
          localStorage.setItem(
            "pharmacy_shop_license_number",
            res.data.pharmacy_shop_license_number
          );
          localStorage.setItem("is_active", res.data.is_active);
          notifySuccess(res.message);
          setUserData({
            ...userData,
            pharmacy_shop_id: res.data.pharmacy_shop_id,
            type_of_pharmacy_shop_id: res.data.type_of_pharmacy_shop_id,
            pharmacy_shop_address: res.data.pharmacy_shop_address,
            pharmacy_shop_license_number: res.data.pharmacy_shop_license_number,
            is_active: res.data.is_active,
          });
          navigate("/VerificationSuccess");
        }
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };

  const fetchShopType = () => {
    simpleGetCallnoTokan(ApiConfig.GET_LIST_OF_LANGUAGAES)
      .then((res) => {
        if (res.success === true) {
          setShopTypes(res?.shop_type);
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchShopType();
  }, []);
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={logo} alt="" className="login_img" />
              <div className="sign_in_wrapper">
                <div className="signinForm signUp">
                  <h1 className="signInTxt">Shop Information</h1>
                  <p className="signInDesc">
                    If you already have an account register <br /> You can
                    <Link className="link_common" to="/">
                      Login here !
                    </Link>
                  </p>
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Shop Name
                    </Form.Label>
                    <img src={shop_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="shop_name"
                      value={ShopInfo.shop_name}
                      onChange={changeEveValue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your shop name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Shop Name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Type of shop
                    </Form.Label>
                    <img src={shop_icon} className="mailImg" alt="" />
                    <Form.Select
                      required
                      name="type_of_pharmacy_shop"
                      value={ShopInfo.type_of_pharmacy_shop}
                      onChange={changeEveValue}
                      className="form-select formInput"
                    >
                      <option value="">Select your shop type</option>
                      {shopTypes &&
                        shopTypes.map((data, index) => {
                          return (
                            <option
                              value={data?.type_of_pharmacy_shop_id}
                              key={index}
                            >
                              {data?.type_of_pharmacy_shop}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please Select Type Of Shop
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Shop Address
                    </Form.Label>
                    <img src={location_icon} className="mailImg" alt="" />
                    <AutocompleteGoogleInput
                      value={ShopInfo.shop_address}
                      required={true}
                      className={"form-control formInput"}
                      placeholder={"Enter your address"}
                      onPlaceSelect={(
                        { lat, lng, address },
                        event,
                        index,
                        name
                      ) => {
                        const locationData = { lat, lng, address };
                        setShopInfo({
                          ...ShopInfo,
                          shop_address: locationData.address,
                          longitude: locationData.lng,
                          latitude: locationData.lat,
                        });
                      }}
                    />
                    {/* <Form.Control
                      required
                      name="shop_address"
                      value={ShopInfo.shop_address}
                      onChange={changeEveValue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter shop address"
                    />
                    */}
                    <Form.Control.Feedback type="invalid">
                      Please Add Shop Address
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      Shop License Number
                    </Form.Label>
                    <img src={document_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="shop_license_number"
                      value={ShopInfo.shop_license_number}
                      onChange={changeEveValue}
                      type="number"
                      className="form-control formInput"
                      placeholder="Enter license number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Add Shop License Number
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="login btn-width-100">
                    <DarkButton
                      // onClick={() => navigate("/VerificationSuccess")}
                      type="submit"
                      classbtn={
                        "cx-btn-dark btn-width-100 btn-height font-17 mb-4"
                      }
                      btn={"Save & Proceed"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg2">
                <p className="tele">
                  {" "}
                  <img src={tele_icon} alt="" /> +91 9509932234
                </p>
                <img src={login} alt="" className="auth_main_img img-fluid" />
                <div className="text_wrapper">
                  <h2 className="tw_title">Sign in to Pharmacy App</h2>
                  <p className="tw-sub_title">Lets Start the Journey...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ClinicInfo;
