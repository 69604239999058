import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import Service_icon from "../../assets/images/icons/Service_icon.svg";
import upload_remove from "../../assets/images/icons/upload_remove.svg";
import delete_upload from "../../assets/images/icons/delete_upload.svg";
import upload_success from "../../assets/images/icons/upload_success.svg";
import upload_doc_icon from "../../assets/images/icons/upload_doc_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import upload_doc_img from "../../assets/images/upload_doc_img.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { useNavigate } from "react-router-dom";

const ClinicSchedule = () => {
  const navigate = useNavigate();
  const [value, onChange] = useState("10:00");
  const [value1, onChange1] = useState("12:00");
  const [value2, onChange2] = useState("01:00");
  const [value3, onChange3] = useState("11:00");
  const [value4, onChange4] = useState("10:00");
  const [value5, onChange5] = useState("08:00");
  const [value6, onChange6] = useState("09:00");
  const [value7, onChange7] = useState("01:00");
  const [value8, onChange8] = useState("01:00");
  const [value9, onChange9] = useState("01:00");
  const [value10, onChange10] = useState("01:00");
  const [value11, onChange11] = useState("01:00");

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <img src={logo} alt="" className="login_img" />
            <div className="clinic_schedule">
              <div className="cs_header_wrapper">
                <div className="row">
                  <div className="col-lg-4"><h2 className="cs_header">SET YOUR SCHEDULE</h2></div>
                  <div className="col-lg-8"><div className="shift_and_days_selector">
                    <div className="weekend_selecter">
                      <label className="schedule_label">
                        Select Weekend Days
                      </label>
                      <div className="weekDays-selector">
                        <input type="checkbox" id="weekday-mon" className="weekday" />
                        <label htmlFor="weekday-mon">Mon</label>
                        <input type="checkbox" id="weekday-tue" className="weekday" />
                        <label htmlFor="weekday-tue">Tue</label>
                        <input type="checkbox" id="weekday-wed" className="weekday" />
                        <label htmlFor="weekday-wed">Wed</label>
                        <input type="checkbox" id="weekday-thu" className="weekday" />
                        <label htmlFor="weekday-thu">Thu</label>
                        <input type="checkbox" id="weekday-fri" className="weekday" />
                        <label htmlFor="weekday-fri">Fri</label>
                        <input type="checkbox" id="weekday-sat" className="weekday" />
                        <label htmlFor="weekday-sat">Sat</label>
                        <input type="checkbox" id="weekday-sun" className="weekday" />
                        <label htmlFor="weekday-sun">Sun</label>
                      </div>
                    </div>
                    <div className="shift_selecter">
                      <label className="schedule_label">Select Shift time</label>
                      <label className="radio-button">
                        <input
                          type="radio"
                          className="radio-button__input"
                          id="choice1-1"
                          name="choice1"
                        />
                        <span className="radio-button__control"></span>
                        <span className="radio-button__label">Morning</span>
                      </label>
                      <label className="radio-button">
                        <input
                          type="radio"
                          className="radio-button__input"
                          id="choice1-2"
                          name="choice1"
                        />
                        <span className="radio-button__control"></span>
                        <span className="radio-button__label">Evening</span>
                      </label>
                      <label className="radio-button">
                        <input
                          type="radio"
                          className="radio-button__input"
                          id="choice1-3"
                          name="choice1"
                        />
                        <span className="radio-button__control"></span>
                        <span className="radio-button__label">Both</span>
                      </label>
                    </div>
                  </div></div>
                </div>


              </div>

              <div className="schedule_cards_wrapper">
                <div className="row">
                  <div className="col-lg-6 mb-4"> <div className="schedule_card">
                    <div className="sc_header_wrapper">
                      <h3 className="sc_header">SLOT SELECTION</h3>
                    </div>
                    <div className="sc_inner">
                      <div className="sc_inner_card sc_top">
                        <h3 className="sct_header">Morning section</h3>
                        <div className="date_wrapper">
                          <label>Start Time</label>
                          <TimePicker onChange={onChange} value={value} />
                        </div>
                        <div className="date_wrapper">
                          <label>End Time</label>
                          <TimePicker onChange={onChange1} value={value1} />
                        </div>
                        <div className="appointment_wrapper">
                          <label>No. of Appointments</label>
                          <div className="selection">
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>01</option>
                              <option value="1">02</option>
                              <option value="2">03</option>
                              <option value="3">04</option>
                            </select> */}
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="doctor_charges_wrapper">
                          <label>Doctor Charge</label>
                          <div className="selection">
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sc_inner_card">
                        <h3 className="sct_header">Evening section</h3>
                        <div className="date_wrapper">
                          <label>Start Time</label>
                          <TimePicker onChange={onChange2} value={value} />
                        </div>
                        <div className="date_wrapper">
                          <label>End Time</label>
                          <TimePicker onChange={onChange3} value={value1} />
                        </div>
                        <div className="appointment_wrapper">
                          <label>No. of Appointments</label>
                          <div className="selection">
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>01</option>
                              <option value="1">02</option>
                              <option value="2">03</option>
                              <option value="3">04</option>
                            </select> */}
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="doctor_charges_wrapper">
                          <label>Doctor Charge</label>
                          <div className="selection">
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></div>
                  <div className="col-lg-6 mb-4">   <div className="schedule_card">
                    <div className="sc_header_wrapper">
                      <h3 className="sc_header">
                        OFF TIME EMERGENCY APPOINTMENT
                      </h3>
                      <div className="sc_switch">
                        <label className="switch">
                          <input type="checkbox" />
                          <div className="slider">
                            <span>NO</span>
                            <span>YES</span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="sc_inner">
                      <div className="sc_inner_card sc_top">
                        <h3 className="sct_header">Morning section</h3>
                        <div className="date_wrapper">
                          <label>Start Time</label>
                          <TimePicker onChange={onChange4} value={value} />
                        </div>
                        <div className="date_wrapper">
                          <label>End Time</label>
                          <TimePicker onChange={onChange5} value={value1} />
                        </div>
                        <div className="appointment_wrapper">
                          <label>No. of Appointments</label>
                          <div className="selection">
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>01</option>
                              <option value="1">02</option>
                              <option value="2">03</option>
                              <option value="3">04</option>
                            </select> */}
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <div className="doctor_charges_wrapper">
                          <label>Doctor Charge</label>
                          <div className="selection">
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sc_inner_card">
                        <h3 className="sct_header">Evening section</h3>
                        <div className="date_wrapper">
                          <label>Start Time</label>
                          <TimePicker onChange={onChange6} value={value} />
                        </div>
                        <div className="date_wrapper">
                          <label>End Time</label>
                          <TimePicker onChange={onChange7} value={value1} />
                        </div>
                        <div className="appointment_wrapper">
                          <label>No. of Appointments</label>
                          <div className="selection">
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>01</option>
                              <option value="1">02</option>
                              <option value="2">03</option>
                              <option value="3">04</option>
                            </select> */}
                          </div>
                        </div>
                        <div className="doctor_charges_wrapper">
                          <label>Doctor Charge</label>
                          <div className="selection">
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></div>
                </div>


              </div>
              <div className="schedule_cards_wrapper mt-0">
                <div className="row">
                  <div className="col-lg-6 mb-4"> <div className="schedule_card">
                    <div className="sc_header_wrapper">
                      <h3 className="sc_header">EMERGENCY APPOINTMENTS</h3>
                      <div className="sc_switch">
                        <label className="switch">
                          <input type="checkbox" />
                          <div className="slider">
                            <span>NO</span>
                            <span>YES</span>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="sc_inner">
                      <div className="sc_inner_card sc_top">
                        <h3 className="sct_header">Morning section</h3>
                        <div className="appointment_wrapper">
                          <label>No. of Appointments</label>
                          <div className="selection">
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>01</option>
                              <option value="1">02</option>
                              <option value="2">03</option>
                              <option value="3">04</option>
                            </select> */}
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                        <h3 className="sct_header">Evening section</h3>

                        <div className="appointment_wrapper">
                          <label>No. of Appointments</label>
                          <div className="selection">
                            {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>01</option>
                              <option value="1">02</option>
                              <option value="2">03</option>
                              <option value="3">04</option>
                            </select> */}
                            <input
                              type="text"
                              className="appointmentLine"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="sc_inner_card">

                        <div className="doctor_charges_wrapper mb-0">
                          <label>Doctor Charge</label>
                          <div className="selection">
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div></div>
                  <div className="col-lg-6 mb-4">
                    <div className="schedule_card">
                      <div className="sc_header_wrapper">
                        <h3 className="sc_header">CLINIC TIME</h3>
                        <div className="sc_switch2">
                          <input type="checkbox" id="appoint" />
                          <label htmlFor="appoint">As per Appointment time</label>
                        </div>
                      </div>
                      <div className="sc_inner">
                        <div className="sc_inner_card sc_top">
                          <h3 className="sct_header">Morning section</h3>
                          <div className="date_wrapper">
                            <label>Start Time</label>
                            <TimePicker onChange={onChange8} value={value} />
                          </div>
                          <div className="date_wrapper">
                            <label>End Time</label>
                            <TimePicker onChange={onChange9} value={value1} />
                          </div>
                        </div>
                        <div className="sc_inner_card">
                          <h3 className="sct_header">Evening section</h3>
                          <div className="date_wrapper">
                            <label>Start Time</label>
                            <TimePicker onChange={onChange10} value={value} />
                          </div>
                          <div className="date_wrapper">
                            <label>End Time</label>
                            <TimePicker onChange={onChange11} value={value1} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

              <div className="login btn-width-100">
                <DarkButton
                  onClick={() => navigate('/ClinicSchedule')}
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                />
                <DarkButton
                  onClick={() => navigate('/VerificationUnderProcess')}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Send Request"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicSchedule;
