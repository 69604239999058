import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Form from "react-bootstrap/Form";
import ic_filter from "../../assets/images/ic_filter.svg";
import { Link } from "react-router-dom";
import approved from "../../assets/images/ic_completed.svg";
import ic_pending from "../../assets/images/icons/ic_pending.svg";
import ic_action from "../../assets/images/pet-shop-img/ActionArrow.svg";
import arrowleft from "../../assets/images/pet-shop-img/arrowleft.svg";
import cancel from "../../assets/images/ic_rejected.svg";
import pending from "../../assets/images/ic_pending.svg";
import { Nav, Tab } from "react-bootstrap";
import ic_right_arrow from "../../assets/images/ic_right_arrow.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
// import ic_pending from "../../assets/images/ic_pending.svg";
import green_check_icon from "../../assets/images/icons/green_check_icon.svg";
import red_check_icon from "../../assets/images/icons/red_check_icon.svg";
import prescription_icon from "../../assets/images/icons/prescription_icon.svg";
import review1 from "../../assets/images/review1.svg";
import review2 from "../../assets/images/review2.svg";
import review3 from "../../assets/images/review3.svg";
import review4 from "../../assets/images/review4.svg";
import medicine from "../../assets/images/pet-shop-img/Belt_img.svg";
import Modal from "react-bootstrap/Modal";
import DocSYmbol from "../../assets/images/icons/DoctorSymbol.svg";
import Signature from "../../assets/images/icons/Signature.svg";
import EmailIcon from "../../assets/images/icons/mail.svg";
import CallIcon from "../../assets/images/icons/call.svg";
import Star from "../../assets/images/pet-shop-img/Star.svg";
import no_review_icon from "../../assets/images/icons/no_review_icon.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  getWithAuthCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
} from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";
import moment from "moment/moment";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Loader from "../../sharedComponent/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import FileSaver from "file-saver";
import WhiteSaveImg from "../../assets/images/whiteSave.svg";

export const getDate = (inputDate) => {
  let date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //dd-mm-yyyy
  //YYYY-MM-DD
  //MM-DD-YYYY
  //dd/mm/yyyy

  var formattedDate = `${day < 10 ? 0 : ""}${day}-${
    month < 10 ? 0 : ""
  }${month}-${year}`;

  return formattedDate;
};

const Order = () => {
  const [appDetails, setAppDetails] = useState("main");

  const { setLoading, userData, loading, socket } = useContext(AppContext);
  const [page, setPage] = useState(1);
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [ProductNameFilter, setProductNameFilter] = useState("");
  const [BrandFilter, setProductBrandFilter] = useState("");
  const [OrderList, setOrderList] = useState([]);
  const [OrderDetails, setOrderDetails] = useState([]);
  const { prescription_image, prescriptionType } =
    OrderDetails.length && OrderDetails[0];

  const [payment_details, setpayment_details] = useState([]);
  const [Orderprescription, setOrderprescription] = useState({});
  const [OrderprescriptionTable, setOrderprescriptionTable] = useState([]);
  const [Orderproduct_summary, setOrderproduct_summary] = useState([]);
  const [OrderproductViste, setOrderproductViste] = useState([]);
  const [OrderproductVisteToday, setOrderproductVisteToday] = useState([]);

  const [OrderproductInvice, setOrderproductInvice] = useState([]);
  const [OrderproductReview, setOrderproductReview] = useState([]);
  const [OrderproductStatus, setOrderproductStatus] = useState([]);
  const [DorupDownStatus, setDorupDownStatus] = useState([]);
  const [StatusId, setStatusId] = useState("");
  const [TotalViste, SetTotalViste] = useState("");

  const [clickedOrder, setClickedOrder] = useState(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  useEffect(() => {
    if (ProductNameFilter === "") {
      setLoading(true);
      getDetails(page);
    } else {
      getDetails(page);
    }
  }, [ProductNameFilter, BrandFilter]);

  const getDetails = (currentPage) => {
    simpleGetCallWithToken(
      ApiConfig.ORDER_DETALIS +
        userData.pharmacy_shop_id +
        "&page=" +
        currentPage +
        "&filter=" +
        ProductNameFilter
    )
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data.total ? data.total : 0;
          setTotalData(total);
          setlast_page(data.last_page);

          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            setOrderList(data.data);
            setLoading(false);
          } else {
            setOrderList([...OrderList, ...LearningData]);
            setLoading(false);
          }
        } else {
          setOrderList([]);
          setlast_page(false);
          notifyError(data?.message)
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getViste = (id) => {
    let newRequestBody = JSON.stringify({
      pet_id: id,
      pharmacy_shop_id: Number(userData.pharmacy_shop_id),
    });
    simplePostAuthCall(ApiConfig.ORDER_BY_VISTE, newRequestBody)
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setOrderproductViste(data.history);
          setOrderproductVisteToday(data.todaysOrders);
          setOrderproductInvice(data.invoices);
          SetTotalViste(data.total);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDetailsByeId = (id) => {
    setLoading(true);

    setStatusId(id);
    simpleGetCallWithToken(
      ApiConfig.ORDER_DETALIS_BY_ID + id + "/" + userData.pharmacy_shop_id
    )
      .then((data) => {
        if (data.success) {
          setOrderDetails(data.data.order_details);
          setpayment_details(data.data.payment_details);
          setOrderprescription(data.prescription);
          setOrderprescriptionTable(data.prescription.prescriptions);
          setOrderproduct_summary(data.product_summary);
          setOrderproductReview(data.data.review);
          setOrderproductStatus(data.status);
          getViste(data.data.animal_id);
          getSellTypes(id);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  function getDateTodayList() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${month}-${date}-${year}`;
  }

  useEffect(() => {
    getSellTypes();
  }, []);

  useEffect(() => {
    socket &&
      socket.on("notification", (data) => {
        getDetails(page);
      });
  }, []);

  const getSellTypes = (id) => {
    getWithAuthCall(
      ApiConfig.GET_STATUS_DROPDOWN +
        id +
        "&pharmacy_id=" +
        userData.pharmacy_id
      // JSON.stringify({ ...customerData })
    ).then((res) => {
      if (res.success === false) {
        setDorupDownStatus([{ status: "Select Status", status_id: "" }]);
      } else {
        setDorupDownStatus([
          { status: "Select Status", status_id: "" },
          ...res.data,
        ]);
      }
    });
  };

  const getStatusUpdate = (id) => {
    let newRequestBody = JSON.stringify({
      orderId: StatusId,
      pharmacy_shop_id: userData.pharmacy_shop_id,
      pharmacy_id: Number(userData.pharmacy_id),
      status: id,
    });
    simplePostAuthCall(ApiConfig.UPDATE_STATUS, newRequestBody)
      .then((data) => {
        if (data.success) {
          notifySuccess(data.message);
          getDetailsByeId(StatusId);
        } else {
          notifyError(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onPageLoad = () => {
    // debugger
    setPage(page + 1);
    if (last_page === false) {
      getDetails(page + 1);
    } else {
    }
  };

  return (
    <>
      <>
        <InfiniteScroll
          dataLength={OrderList?.length}
          next={onPageLoad}
          // hasMore={true}
          hasMore={OrderList.length !== TotalData}
          scrollableTarget="scroll-table"
          loader={
            <h4
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            ></h4>
          }
        >
          {loading === true ? (
            <Loader />
          ) : (
            <motion.div
              variants={aninations}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.3 }}
              className="appointment-wrapper reports_wrapper"
              id="cx-main"
            >
              <div className="reportsTabs">
                {appDetails === "main" ? (
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <div className="search-filter-wrapper">
                        <div className="search-filter-left">
                          <div className="row">
                            <div className="col-lg-5 mb-2">
                              <input
                                type="text"
                                className="form-control serach-input1 me-4"
                                placeholder="Search by Name, ID..."
                                value={ProductNameFilter}
                                onChange={(e) => {
                                  setProductNameFilter(e.target.value);
                                }}
                              />
                            </div>
                            {/* <div className="col-lg-5 mb-2">
                      <div className="selectBox">
                      <input
                        type="text"
                        className="form-control serach-input1 me-4"
                        placeholder="Search by Name, ID..."
                        value={BrandFilter}
                        onChange={(e) => {
                          setProductBrandFilter(e.target.value);
                        }}
                      />
                      
                      </div>
                    </div> */}
                            {/* <div className="col-lg-1">
                              <button className="fltr-btn">
                                <img src={ic_filter} alt="" />
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper heighAdjustABle">
                        <table className="defaultTable">
                          <thead>
                            <tr>
                              <th>Sr No. </th>
                              <th>Order ID</th>
                              <th>Order Date</th>
                              <th>Customer</th>
                              <th>Item</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th className="center">Action</th>
                            </tr>
                          </thead>
                          <tbody className="tableBody">
                            {OrderList && OrderList.length > 0 ? (
                              OrderList.map((itemlist, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{itemlist.order_id}</td>
                                    <td>{getDate(itemlist.order_date)}</td>
                                    <td>{itemlist.first_name}</td>
                                    <td>{itemlist.no_of_items}</td>
                                    <td>₹{itemlist.total_amount}</td>
                                    {itemlist.status === "ready to dispatch" ? (
                                      <>
                                        <td className="status_complete">
                                          <img
                                            src={ic_pending}
                                            className="checks tableImg"
                                            alt=""
                                          />
                                          ready to dispatch
                                        </td>
                                      </>
                                    ) : itemlist.status ===
                                      "order dispatched" ? (
                                      <td className="status_complete">
                                        <img
                                          src={ic_pending}
                                          className="checks tableImg"
                                          alt=""
                                        />
                                        {itemlist.status}
                                      </td>
                                    ) : itemlist.status === "order placed" ? (
                                      <>
                                        <td className="status_complete">
                                          <img
                                            src={ic_pending}
                                            className="checks tableImg"
                                            alt=""
                                          />
                                          Pending
                                        </td>
                                      </>
                                    ) : itemlist.status ===
                                      "delivery person assigned" ? (
                                      <td className="status_complete">
                                        <img
                                          src={ic_pending}
                                          className="checks tableImg"
                                          alt=""
                                        />
                                        {itemlist.status}
                                      </td>
                                    ) : itemlist.status ===
                                      "order cancelled" ? (
                                      <>
                                        <td className="status_complete">
                                          <img
                                            src={cancel}
                                            className="checks tableImg"
                                            alt=""
                                          />
                                          cancelled
                                        </td>
                                      </>
                                    ) : itemlist.status ===
                                      "order confirmed" ? (
                                      <>
                                        <>
                                          <td className="status_complete">
                                            <img
                                              src={ic_completed}
                                              className="checks tableImg"
                                              alt=""
                                            />
                                            Order confirmed
                                          </td>
                                        </>
                                      </>
                                    ) : itemlist.status ===
                                      "order delivered" ? (
                                      <>
                                        <>
                                          <td className="status_complete">
                                            <img
                                              src={ic_completed}
                                              className="checks tableImg"
                                              alt=""
                                            />
                                            Order delivered
                                          </td>
                                        </>
                                      </>
                                    ) : (
                                      <>
                                        <td className="status_complete">
                                          <img
                                            src={pending}
                                            className="checks tableImg"
                                            alt=""
                                          />
                                          invalid
                                        </td>
                                      </>
                                    )}

                                    <td className="center">
                                      <Link
                                        onClick={() => {
                                          setAppDetails("complete");
                                          getDetailsByeId(itemlist.order_id);
                                        }}
                                      >
                                        <img src={ic_action} alt="" />
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </tbody>
                        </table>
                        {OrderList.length ? (
                          ""
                        ) : (
                          <p
                            style={{
                              fontSize: "20px",
                              marginTop: "200px",
                            }}
                            className=" text-center justify-content-center align-items-center"
                          >
                            Oops! It doesn't look like there are any Order?
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {appDetails === "complete" ? (
                  <div className="OrderInner">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="OrederInnerHead">
                          <img
                            src={arrowleft}
                            alt=""
                            onClick={() => {
                              setAppDetails("main");
                            }}
                          />
                          <p className="resultOrder">
                            Showing result for Order Id :{" "}
                            <span>#{StatusId}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-8 mt-4">
                        <div className="orderInnerTabSection">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills d-flex"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">Order Details</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">Status</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="3">
                                  Payment Details
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="4">
                                  Review & Ratings
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        {OrderDetails &&
                                        OrderDetails.length > 0 ? (
                                          OrderDetails.map(
                                            (itemlist, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td className="labelName">
                                                      Order ID
                                                    </td>
                                                    <td className="ContainName">
                                                      #{itemlist.order_id}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Order Date
                                                    </td>
                                                    <td className="ContainName">
                                                      {getDate(
                                                        itemlist.order_date
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Delivery Date
                                                    </td>
                                                    <td className="ContainName">
                                                      {getDate(
                                                        itemlist.delivery_date
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      No. of Items
                                                    </td>
                                                    <td className="ContainName">
                                                      {itemlist.no_of_items}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName pb-0">
                                                      Total Amount
                                                    </td>
                                                    <td className="ContainName pb-0">
                                                      ₹{itemlist.total_amount}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </table>
                                    </div>
                                    {OrderDetails.length ? (
                                      ""
                                    ) : (
                                      <p
                                        style={{
                                          fontSize: "20px",
                                          marginTop: "200px",
                                        }}
                                        className=" text-center justify-content-center align-items-center"
                                      >
                                        Oops! It doesn't look like there are any
                                        Inventory?
                                      </p>
                                    )}
                                    <div className="col-lg-6 ps-5">
                                      <table>
                                        {OrderDetails &&
                                        OrderDetails.length > 0 ? (
                                          OrderDetails.map(
                                            (itemlist, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td className="labelName">
                                                      Customer Name
                                                    </td>
                                                    <td className="ContainName">
                                                      {
                                                        itemlist.customer_first_name
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Contact Number
                                                    </td>
                                                    <td className="ContainName">
                                                      {itemlist.contact_number}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Email ID
                                                    </td>
                                                    <td className="ContainName">
                                                      {" "}
                                                      {itemlist.email_id}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Pet Type
                                                    </td>
                                                    <td className="ContainName">
                                                      {itemlist.pet_type}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName pb-0">
                                                      Pet Age
                                                    </td>
                                                    <td className="ContainName pb-0">
                                                      {" "}
                                                      {itemlist.pet_age}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <table>
                                        {/* <tr>
                                  <td className="labelName p-0">Order ID</td>
                                  <td className="ContainName p-0">#123456</td>
                                </tr> */}
                                      </table>
                                    </div>
                                    <div className="col-lg-6 d-flex justify-content-end">
                                      <select
                                        onChange={(e) => {
                                          getStatusUpdate(e.target.value);
                                        }}
                                        class="form-select"
                                        aria-label="Default select example"
                                        value={
                                          DorupDownStatus &&
                                          DorupDownStatus.length
                                            ? DorupDownStatus[0].status_id
                                            : ""
                                        }
                                      >
                                        {DorupDownStatus &&
                                          DorupDownStatus.length &&
                                          DorupDownStatus.map(
                                            (sellType, index) => {
                                              return (
                                                <option
                                                  key={"delivery" + index}
                                                  value={sellType.status_id}
                                                >
                                                  {sellType.status}
                                                </option>
                                              );
                                            }
                                          )}
                                      </select>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="status_table_wrapper">
                                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>User</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {OrderproductStatus &&
                                              OrderproductStatus.length > 0 ? (
                                                OrderproductStatus.map(
                                                  (itemlist, index) => {
                                                    return (
                                                      <>
                                                        <tr>
                                                          <td>
                                                            {getDate(
                                                              itemlist.date
                                                            )}
                                                          </td>
                                                          <td>
                                                            {itemlist.status}
                                                          </td>
                                                          <td>
                                                            {itemlist.user}
                                                          </td>
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="3">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        {payment_details &&
                                        payment_details.length > 0 ? (
                                          payment_details.map(
                                            (itemlist, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td className="labelName">
                                                      Order ID
                                                    </td>
                                                    <td className="ContainName">
                                                      #{itemlist.order_id}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Order Date
                                                    </td>
                                                    <td className="ContainName">
                                                      {getDate(
                                                        itemlist.order_date
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Delivery Date
                                                    </td>
                                                    <td className="ContainName">
                                                      {getDate(
                                                        itemlist.delivery_date
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      No. of Items
                                                    </td>
                                                    <td className="ContainName">
                                                      {itemlist.no_of_items}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName pb-0">
                                                      Total Amount
                                                    </td>
                                                    <td className="ContainName pb-0">
                                                      ₹{itemlist.total_amount}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-4">
                                      <table>
                                        {payment_details &&
                                        payment_details.length > 0 ? (
                                          payment_details.map(
                                            (itemlist, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td className="labelName">
                                                      Mode Of Payment
                                                    </td>
                                                    <td className="ContainName">
                                                      {itemlist.payment_mode}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Transaction ID
                                                    </td>
                                                    <td className="ContainName">
                                                      #{itemlist.transaction_id}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Transaction Date
                                                    </td>
                                                    <td className="ContainName">
                                                      {getDate(
                                                        itemlist.transaction_date
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td className="labelName">
                                                      Payment Status
                                                    </td>
                                                    <td className="ContainName">
                                                      {/* <img
                                      src={pending}
                                      className="checks tableImg"
                                      alt=""
                                    /> */}
                                                      {itemlist.paymet_status}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="4">
                                {OrderproductReview &&
                                OrderproductReview.length > 0 ? (
                                  OrderproductReview.map((itemlist, index) => {
                                    return (
                                      <>
                                        <div className="review_ratings_wrapper">
                                          <div className="review_title_wrapper">
                                            <p className="review_title">
                                              {itemlist.review_title}
                                            </p>
                                            <div className="rating_star_wrapper">
                                              <img src={Star} alt="" />
                                              <span>{itemlist.rating}</span>
                                            </div>
                                          </div>
                                          <div className="review_description">
                                            <p className="review_text">
                                              {itemlist.review}
                                            </p>
                                            {/* <div className="review_img_wrapper">
                                            <img src={review1} alt="" />
                                            <img src={review2} alt="" />
                                            <img src={review3} alt="" />
                                            <img src={review4} alt="" />
                                          </div> */}
                                          </div>
                                        </div>
                                        <div className="review_ratings_wrapper no_reviews">
                                          <img src={no_review_icon} alt="" />
                                          <p className="no_review_text">
                                            No Reviews Found...
                                          </p>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                        {Orderprescription?.doctor_name &&
                        Orderprescription?.animal_name ? (
                          <div className="prescription_wrapper">
                            <p className="pw_title">Prescription</p>
                            <div
                              className="pw_card"
                              onClick={() => setClickedOrder(true)}
                            >
                              <img src={prescription_icon} alt="" />
                              {/* <p className="pwc_text">{getDateTodayList()}</p> */}
                            </div>
                          </div>
                        ) : null}
                        <div className="product_summary_wrapper ">
                          <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                            <div className="lc_top">
                              <div className="lct_header_wrapper">
                                <p>Product Summary</p>
                              </div>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Particular</th>
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Orderproduct_summary &&
                                Orderproduct_summary.length > 0 ? (
                                  Orderproduct_summary.map(
                                    (itemlist, index) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                              <label
                                                htmlFor=""
                                                className="photo"
                                              >
                                                <img
                                                  src={
                                                    itemlist.product_image_path
                                                  }
                                                  alt=""
                                                />

                                                {itemlist.product}
                                              </label>
                                            </td>
                                            <td>{itemlist.quantity}</td>
                                            <td>₹{itemlist.product_rate}</td>
                                            <td>₹{itemlist.amount}</td>
                                          </tr>
                                        </>
                                      );
                                    }
                                  )
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="download_btn"
                          onClick={() => {
                            console.log(
                              "prescription_image,",
                              prescription_image
                            );
                            console.log("prescriptionType,", prescriptionType);
                            FileSaver.saveAs(
                              prescription_image,
                              `image.${prescriptionType}`,
                              { autoBom: true }
                            );
                          }}
                        >
                          <button>
                            <img src={WhiteSaveImg} alt="im" />
                            Download Prescription
                          </button>
                        </div>
                        {/* <div className="order_status_wrapper_bottom order_complete">
                  <img src={approved} alt="" />
                  <p>Order Successfully Completed !</p>
                </div> */}
                      </div>
                      <div className="col-lg-4 mt-4">
                        <div className="right">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">
                                  Orders <span>({TotalViste})</span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">
                                  Invoices <span>({TotalViste})</span>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="visits-tab-wrapper">
                                  <div className="today-heading">
                                    <label htmlFor="" className="key">
                                      Today
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <>
                                    {OrderproductVisteToday &&
                                    OrderproductVisteToday.length > 0 ? (
                                      OrderproductVisteToday.map(
                                        (itemlist, index) => {
                                          return (
                                            <>
                                              <div className="app-time-date active mainBoxy">
                                                {itemlist.visits &&
                                                itemlist.visits.length > 0 ? (
                                                  itemlist.visits.map(
                                                    (list, index) => {
                                                      return (
                                                        <>
                                                          <div
                                                            className="left-app-time-date boxyFormat"
                                                            onClick={() => {
                                                              getDetailsByeId(
                                                                list.order_id
                                                              );
                                                            }}
                                                          >
                                                            <div className="firstDiv">
                                                              <label
                                                                htmlFor=""
                                                                className="value"
                                                              >
                                                                Order ID : #
                                                                {list.order_id}
                                                              </label>
                                                              <br />
                                                              <label
                                                                htmlFor=""
                                                                className="status"
                                                              >
                                                                ₹
                                                                {
                                                                  list.total_amount
                                                                }{" "}
                                                                |{" "}
                                                                <img
                                                                  src={
                                                                    green_check_icon
                                                                  }
                                                                  alt=""
                                                                />{" "}
                                                                {list.status}
                                                              </label>
                                                            </div>
                                                            <div className="right-app-time-date">
                                                              <img
                                                                src={
                                                                  ic_right_arrow
                                                                }
                                                                alt=""
                                                              />
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <> No data </>
                                                )}
                                              </div>
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <p className="scheule_tody">
                                        No Orders Today
                                      </p>
                                    )}

                                    {OrderproductViste &&
                                    OrderproductViste.length > 0 ? (
                                      OrderproductViste.map(
                                        (itemlist, index) => {
                                          return (
                                            <>
                                              <div className="today-heading">
                                                <label
                                                  htmlFor=""
                                                  className="key line-style"
                                                >
                                                  {getDate(itemlist.visit_date)}
                                                </label>

                                                {/* <div className="line"></div> */}
                                              </div>

                                              {itemlist.visits &&
                                              itemlist.visits.length > 0 ? (
                                                itemlist.visits.map(
                                                  (list, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          className="app-time-date"
                                                          onClick={() => {
                                                            getDetailsByeId(
                                                              list.order_id
                                                            );
                                                          }}
                                                        >
                                                          <div className="left-app-time-date">
                                                            <label
                                                              htmlFor=""
                                                              className="value"
                                                            >
                                                              Order ID : #
                                                              {list.order_id}
                                                            </label>
                                                            <div>
                                                              <label
                                                                htmlFor=""
                                                                className="status"
                                                              >
                                                                ₹{" "}
                                                                {
                                                                  list.total_amount
                                                                }
                                                                |{" "}
                                                                <img
                                                                  src={
                                                                    green_check_icon
                                                                  }
                                                                  alt=""
                                                                />{" "}
                                                                {list.status}
                                                              </label>
                                                            </div>
                                                          </div>
                                                          <div className="right-app-time-date">
                                                            <img
                                                              src={
                                                                ic_right_arrow
                                                              }
                                                              alt=""
                                                            />
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <>
                                        <p className="scheule_tody">
                                          No history
                                        </p>
                                      </>
                                    )}
                                  </>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="invoices-tab-wrapper">
                                  <div className="table-wrapper">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Sr. No</th>
                                          <th>Date</th>
                                          <th>Amount</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {OrderproductInvice &&
                                        OrderproductInvice.length > 0 ? (
                                          OrderproductInvice.map(
                                            (itemlist, index) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                      {getDate(
                                                        itemlist.visit_date
                                                      )}
                                                    </td>
                                                    <td>
                                                      ₹{itemlist.total_amount}
                                                    </td>
                                                    <td>
                                                      <img
                                                        src={ic_pending}
                                                        className="status-ic"
                                                        alt=""
                                                      />
                                                      <label
                                                        htmlFor=""
                                                        className="pending-txt"
                                                      >
                                                        {itemlist.status}
                                                      </label>
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {appDetails === "pending" ? (
                  <div className="OrderInner">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="OrederInnerHead">
                          <img
                            src={arrowleft}
                            alt=""
                            onClick={() => {
                              setAppDetails("main");
                            }}
                          />
                          <p className="resultOrder">
                            Showing result for Order Id : <span>12</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-8 mt-4">
                        <div className="orderInnerTabSection">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills d-flex"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">Order Details</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">Status</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="3">
                                  Payment Details
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="4">
                                  Review & Ratings
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        {/* <tr>
                                  <td className="labelName">Order ID</td>
                                  <td className="ContainName">#123456</td>
                                </tr> */}
                                        <tr>
                                          <td className="labelName">
                                            Order Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 04:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Delivery Date
                                          </td>
                                          <td className="ContainName">
                                            05-02-2023
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            No. of Items
                                          </td>
                                          <td className="ContainName">10</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Total Amount
                                          </td>
                                          <td className="ContainName pb-0">
                                            ₹450
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Customer Name
                                          </td>
                                          <td className="ContainName">
                                            Ronald Miller
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Contact Number
                                          </td>
                                          <td className="ContainName">
                                            +91 99999 9999
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Email ID
                                          </td>
                                          <td className="ContainName">
                                            xyz@gmail.com
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Pet Type
                                          </td>
                                          <td className="ContainName">Dog</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Pet Age
                                          </td>
                                          <td className="ContainName pb-0">
                                            14 Monts
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <table>
                                        <tr>
                                          <td className="labelName p-0">
                                            Order ID
                                          </td>
                                          <td className="ContainName p-0">
                                            #123456
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 d-flex justify-content-end">
                                      <select
                                        class="form-select"
                                        aria-label="Default select example"
                                      >
                                        <option selected>In Progress</option>
                                        <option value="1">Complete</option>
                                        <option value="2">Cancelled</option>
                                      </select>
                                      <button className="cx-btn-dark">
                                        Update
                                      </button>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="status_table_wrapper">
                                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>User</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>02-02-2023</td>
                                                <td>Order Accepted</td>
                                                <td>Rohan Sharma</td>
                                              </tr>
                                              <tr>
                                                <td>02-02-2023</td>
                                                <td>Order In-Progress</td>
                                                <td>Sunil Shetty</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="3">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Order ID
                                          </td>
                                          <td className="ContainName">
                                            #123456
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Order Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 04:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Delivery Date
                                          </td>
                                          <td className="ContainName">
                                            05-02-2023
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            No. of Items
                                          </td>
                                          <td className="ContainName">10</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Total Amount
                                          </td>
                                          <td className="ContainName pb-0">
                                            ₹450
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-4">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Mode Of Payment
                                          </td>
                                          <td className="ContainName">
                                            Online
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Transaction ID
                                          </td>
                                          <td className="ContainName">
                                            #12346598
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Transaction Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 05:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Payment Status
                                          </td>
                                          <td className="ContainName">
                                            <img
                                              src={pending}
                                              className="checks tableImg"
                                              alt=""
                                            />
                                            Pending
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="4">
                                <div className="review_ratings_wrapper">
                                  <div className="review_title_wrapper">
                                    <p className="review_title">
                                      Best Products Must Try
                                    </p>
                                    <div className="rating_star_wrapper">
                                      <img src={Star} alt="" />
                                      <span>4.3</span>
                                    </div>
                                  </div>
                                  <div className="review_description">
                                    <p className="review_text">
                                      Ut enim ad minima veniam, quis nostrum
                                      exercitationem ullam corporis suscipit
                                      laboriosam, nisi ut al Ut enim ad minima
                                      veniam, quis nostrum exercitationem ullam
                                      corporis suscipit laboriosam, nisi ut al
                                    </p>
                                    <div className="review_img_wrapper">
                                      <img src={review1} alt="" />
                                      <img src={review2} alt="" />
                                      <img src={review3} alt="" />
                                      <img src={review4} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="review_ratings_wrapper no_reviews">
                                  <img src={no_review_icon} alt="" />
                                  <p className="no_review_text">
                                    No Reviews Found...
                                  </p>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>

                        <div className="prescription_wrapper">
                          <p className="pw_title">Prescription</p>
                          <div
                            className="pw_card"
                            onClick={() => setClickedOrder(true)}
                          >
                            <img src={prescription_icon} alt="" />
                            {/* <p className="pwc_text">02-02-2023</p> */}
                          </div>
                        </div>
                        <div className="product_summary_wrapper ">
                          <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                            <div className="lc_top">
                              <div className="lct_header_wrapper">
                                <p>Product Summary</p>
                              </div>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Particular</th>
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="Button_Bottom mb-3">
                          <button className="cx-btn-Light">Reject</button>
                          <button className="cx-btn-dark">Accept</button>
                        </div>
                      </div>
                      <div className="col-lg-4 mt-4">
                        <div className="right">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">
                                  Visits <span>(10)</span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">
                                  Invoices <span>(10)</span>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="visits-tab-wrapper">
                                  <div className="today-heading">
                                    <label htmlFor="" className="key">
                                      Today
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date active">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="today-heading">
                                    <label
                                      htmlFor=""
                                      className="key line-style"
                                    >
                                      28-06-2023
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="today-heading">
                                    <label
                                      htmlFor=""
                                      className="key line-style"
                                    >
                                      28-06-2023
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label
                                          htmlFor=""
                                          className="status_red"
                                        >
                                          <img src={red_check_icon} alt="" />{" "}
                                          Cancelled
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="invoices-tab-wrapper">
                                  <div className="table-wrapper">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Sr. No</th>
                                          <th>Date</th>
                                          <th>Amount</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {appDetails === "progress" ? (
                  <div className="OrderInner">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="OrederInnerHead">
                          <img
                            src={arrowleft}
                            alt=""
                            onClick={() => {
                              setAppDetails("main");
                            }}
                          />
                          <p className="resultOrder">
                            Showing result for Order Id : <span>12</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-8 mt-4">
                        <div className="orderInnerTabSection">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills d-flex"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">Order Details</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">Status</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="3">
                                  Payment Details
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="4">
                                  Review & Ratings
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Order ID
                                          </td>
                                          <td className="ContainName">
                                            #123456
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Order Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 04:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Delivery Date
                                          </td>
                                          <td className="ContainName">
                                            05-02-2023
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            No. of Items
                                          </td>
                                          <td className="ContainName">10</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Total Amount
                                          </td>
                                          <td className="ContainName pb-0">
                                            ₹450
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Customer Name
                                          </td>
                                          <td className="ContainName">
                                            Ronald Miller
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Contact Number
                                          </td>
                                          <td className="ContainName">
                                            +91 99999 9999
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Email ID
                                          </td>
                                          <td className="ContainName">
                                            xyz@gmail.com
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Pet Type
                                          </td>
                                          <td className="ContainName">Dog</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Pet Age
                                          </td>
                                          <td className="ContainName pb-0">
                                            14 Monts
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <table>
                                        <tr>
                                          <td className="labelName p-0">
                                            Order ID
                                          </td>
                                          <td className="ContainName p-0">
                                            #123456
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 d-flex justify-content-end">
                                      <select
                                        class="form-select"
                                        aria-label="Default select example"
                                      >
                                        <option selected>In Progress</option>
                                        <option value="1">Complete</option>
                                        <option value="2">Cancelled</option>
                                      </select>
                                      <button className="cx-btn-dark">
                                        Update
                                      </button>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="status_table_wrapper">
                                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>User</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>02-02-2023</td>
                                                <td>Order Accepted</td>
                                                <td>Rohan Sharma</td>
                                              </tr>
                                              <tr>
                                                <td>02-02-2023</td>
                                                <td>Order In-Progress</td>
                                                <td>Sunil Shetty</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="3">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Order ID
                                          </td>
                                          <td className="ContainName">
                                            #123456
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Order Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 04:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Delivery Date
                                          </td>
                                          <td className="ContainName">
                                            05-02-2023
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            No. of Items
                                          </td>
                                          <td className="ContainName">10</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Total Amount
                                          </td>
                                          <td className="ContainName pb-0">
                                            ₹450
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-4">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Mode Of Payment
                                          </td>
                                          <td className="ContainName">
                                            Online
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Transaction ID
                                          </td>
                                          <td className="ContainName">
                                            #12346598
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Transaction Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 05:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Payment Status
                                          </td>
                                          <td className="ContainName">
                                            <img
                                              src={pending}
                                              className="checks tableImg"
                                              alt=""
                                            />
                                            Pending
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="4">
                                <div className="review_ratings_wrapper">
                                  <div className="review_title_wrapper">
                                    <p className="review_title">
                                      Best Products Must Try
                                    </p>
                                    <div className="rating_star_wrapper">
                                      <img src={Star} alt="" />
                                      <span>4.3</span>
                                    </div>
                                  </div>
                                  <div className="review_description">
                                    <p className="review_text">
                                      Ut enim ad minima veniam, quis nostrum
                                      exercitationem ullam corporis suscipit
                                      laboriosam, nisi ut al Ut enim ad minima
                                      veniam, quis nostrum exercitationem ullam
                                      corporis suscipit laboriosam, nisi ut al
                                    </p>
                                    <div className="review_img_wrapper">
                                      <img src={review1} alt="" />
                                      <img src={review2} alt="" />
                                      <img src={review3} alt="" />
                                      <img src={review4} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="review_ratings_wrapper no_reviews">
                                  <img src={no_review_icon} alt="" />
                                  <p className="no_review_text">
                                    No Reviews Found...
                                  </p>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>

                        <div className="prescription_wrapper">
                          <p className="pw_title">Prescription</p>
                          <div
                            className="pw_card"
                            onClick={() => setClickedOrder(true)}
                          >
                            <img src={prescription_icon} alt="" />
                            <p className="pwc_text">02-02-2023</p>
                          </div>
                        </div>
                        <div className="product_summary_wrapper ">
                          <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                            <div className="lc_top">
                              <div className="lct_header_wrapper">
                                <p>Product Summary</p>
                              </div>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Particular</th>
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="order_status_wrapper_bottom order_progress">
                          <img src={pending} alt="" />
                          <p>This order is in progress. Delivery Pending</p>
                        </div>
                      </div>
                      <div className="col-lg-4 mt-4">
                        <div className="right">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">
                                  Visits <span>(10)</span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">
                                  Invoices <span>(10)</span>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="visits-tab-wrapper">
                                  <div className="today-heading">
                                    <label htmlFor="" className="key">
                                      Today
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date active">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="today-heading">
                                    <label
                                      htmlFor=""
                                      className="key line-style"
                                    >
                                      28-06-2023
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="today-heading">
                                    <label
                                      htmlFor=""
                                      className="key line-style"
                                    >
                                      28-06-2023
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label
                                          htmlFor=""
                                          className="status_red"
                                        >
                                          <img src={red_check_icon} alt="" />{" "}
                                          Cancelled
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="invoices-tab-wrapper">
                                  <div className="table-wrapper">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Sr. No</th>
                                          <th>Date</th>
                                          <th>Amount</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {appDetails === "cancelled" ? (
                  <div className="OrderInner">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="OrederInnerHead">
                          <img
                            src={arrowleft}
                            alt=""
                            onClick={() => {
                              setAppDetails("main");
                            }}
                          />
                          <p className="resultOrder">
                            Showing result for Order Id : <span>12</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-8 mt-4">
                        <div className="orderInnerTabSection">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills d-flex"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">Order Details</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">Status</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="3">
                                  Payment Details
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="4">
                                  Review & Ratings
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Order ID
                                          </td>
                                          <td className="ContainName">
                                            #123456
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Order Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 04:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Delivery Date
                                          </td>
                                          <td className="ContainName">
                                            05-02-2023
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            No. of Items
                                          </td>
                                          <td className="ContainName">10</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Total Amount
                                          </td>
                                          <td className="ContainName pb-0">
                                            ₹450
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-5">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Customer Name
                                          </td>
                                          <td className="ContainName">
                                            Ronald Miller
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Contact Number
                                          </td>
                                          <td className="ContainName">
                                            +91 99999 9999
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Email ID
                                          </td>
                                          <td className="ContainName">
                                            xyz@gmail.com
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Pet Type
                                          </td>
                                          <td className="ContainName">Dog</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Pet Age
                                          </td>
                                          <td className="ContainName pb-0">
                                            14 Monts
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <table>
                                        <tr>
                                          <td className="labelName p-0">
                                            Order ID
                                          </td>
                                          <td className="ContainName p-0">
                                            #123456
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 d-flex justify-content-end">
                                      <select
                                        class="form-select"
                                        aria-label="Default select example"
                                      >
                                        <option selected>In Progress</option>
                                        <option value="1">Complete</option>
                                        <option value="2">Cancelled</option>
                                      </select>
                                      <button className="cx-btn-dark">
                                        Update
                                      </button>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="status_table_wrapper">
                                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination">
                                          <table>
                                            <thead>
                                              <tr>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>User</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>02-02-2023</td>
                                                <td>Order Accepted</td>
                                                <td>Rohan Sharma</td>
                                              </tr>
                                              <tr>
                                                <td>02-02-2023</td>
                                                <td>Order In-Progress</td>
                                                <td>Sunil Shetty</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="3">
                                <div className="innerContain">
                                  <div className="row">
                                    <div className="col-lg-6 borderLeft">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Order ID
                                          </td>
                                          <td className="ContainName">
                                            #123456
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Order Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 04:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Delivery Date
                                          </td>
                                          <td className="ContainName">
                                            05-02-2023
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            No. of Items
                                          </td>
                                          <td className="ContainName">10</td>
                                        </tr>
                                        <tr>
                                          <td className="labelName pb-0">
                                            Total Amount
                                          </td>
                                          <td className="ContainName pb-0">
                                            ₹450
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                    <div className="col-lg-6 ps-4">
                                      <table>
                                        <tr>
                                          <td className="labelName">
                                            Mode Of Payment
                                          </td>
                                          <td className="ContainName">
                                            Online
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Transaction ID
                                          </td>
                                          <td className="ContainName">
                                            #12346598
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Transaction Date
                                          </td>
                                          <td className="ContainName">
                                            02-02-2023, 05:58s
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="labelName">
                                            Payment Status
                                          </td>
                                          <td className="ContainName">
                                            <img
                                              src={pending}
                                              className="checks tableImg"
                                              alt=""
                                            />
                                            Pending
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="4">
                                <div className="review_ratings_wrapper">
                                  <div className="review_title_wrapper">
                                    <p className="review_title">
                                      Best Products Must Try
                                    </p>
                                    <div className="rating_star_wrapper">
                                      <img src={Star} alt="" />
                                      <span>4.3</span>
                                    </div>
                                  </div>
                                  <div className="review_description">
                                    <p className="review_text">
                                      Ut enim ad minima veniam, quis nostrum
                                      exercitationem ullam corporis suscipit
                                      laboriosam, nisi ut al Ut enim ad minima
                                      veniam, quis nostrum exercitationem ullam
                                      corporis suscipit laboriosam, nisi ut al
                                    </p>
                                    <div className="review_img_wrapper">
                                      <img src={review1} alt="" />
                                      <img src={review2} alt="" />
                                      <img src={review3} alt="" />
                                      <img src={review4} alt="" />
                                    </div>
                                  </div>
                                </div>
                                <div className="review_ratings_wrapper no_reviews">
                                  <img src={no_review_icon} alt="" />
                                  <p className="no_review_text">
                                    No Reviews Found...
                                  </p>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>

                        <div className="prescription_wrapper">
                          <p className="pw_title">Prescription</p>
                          <div
                            className="pw_card"
                            onClick={() => setClickedOrder(true)}
                          >
                            <img src={prescription_icon} alt="" />
                            <p className="pwc_text">02-02-2023</p>
                          </div>
                        </div>
                        <div className="product_summary_wrapper ">
                          <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                            <div className="lc_top">
                              <div className="lct_header_wrapper">
                                <p>Product Summary Oid</p>
                              </div>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Particular</th>
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>
                                    <label htmlFor="" className="photo">
                                      <img src={medicine} alt="" />
                                      Pets Empire Stainless Steel Dog Bowl
                                      (Medium, Set of 2)
                                    </label>
                                  </td>
                                  <td>1</td>
                                  <td>₹50</td>
                                  <td>₹50</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="order_status_wrapper_bottom order_cancelled">
                          <img src={cancel} alt="" />
                          <p>This Order has been cancelled by the customer.</p>
                        </div>
                      </div>
                      <div className="col-lg-4 mt-4">
                        <div className="right">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="1"
                          >
                            <Nav
                              variant="pills"
                              id="newTabMai"
                              className="tob_nav_pills"
                            >
                              <Nav.Item>
                                <Nav.Link eventKey="1">
                                  Visits <span>(10)</span>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="2">
                                  Invoices <span>(10)</span>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <Tab.Content>
                              <Tab.Pane eventKey="1">
                                <div className="visits-tab-wrapper">
                                  <div className="today-heading">
                                    <label htmlFor="" className="key">
                                      Today
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date active">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="today-heading">
                                    <label
                                      htmlFor=""
                                      className="key line-style"
                                    >
                                      28-06-2023
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="today-heading">
                                    <label
                                      htmlFor=""
                                      className="key line-style"
                                    >
                                      28-06-2023
                                    </label>
                                    <div className="line"></div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label htmlFor="" className="status">
                                          ₹500 |{" "}
                                          <img src={green_check_icon} alt="" />{" "}
                                          Completed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                  <div className="app-time-date">
                                    <div className="left-app-time-date">
                                      <label htmlFor="" className="value">
                                        Order ID : #123456789
                                      </label>
                                      <div>
                                        <label
                                          htmlFor=""
                                          className="status_red"
                                        >
                                          <img src={red_check_icon} alt="" />{" "}
                                          Cancelled
                                        </label>
                                      </div>
                                    </div>
                                    <div className="right-app-time-date">
                                      <img src={ic_right_arrow} alt="" />
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="2">
                                <div className="invoices-tab-wrapper">
                                  <div className="table-wrapper">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Sr. No</th>
                                          <th>Date</th>
                                          <th>Amount</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>1</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>2</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>3</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>4</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>5</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_pending}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Pending
                                            </label>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>6</td>
                                          <td>02-02-2023</td>
                                          <td>₹300</td>
                                          <td>
                                            <img
                                              src={ic_completed}
                                              className="status-ic"
                                              alt=""
                                            />
                                            <label
                                              htmlFor=""
                                              className="pending-txt"
                                            >
                                              Complete
                                            </label>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {/* Modal For Order */}
              <Modal
                show={clickedOrder}
                onHide={() => setClickedOrder(false)}
                size="lg"
              >
                <Modal.Body>
                  <div className="orderModalInvoice">
                    <div className="modalHeading">
                      <div className="upperBlue">
                        <h1 className="docName">
                          Dr. {Orderprescription.doctor_name}{" "}
                        </h1>
                        {/* <label className="domainLab">Orthopedic </label> */}
                        <label className="certificateLab">
                          Prescription
                          {/* Certifcation {Orderprescription.certification_no} */}
                        </label>
                      </div>
                      <div className="docSymbolBox">
                        <img src={DocSYmbol} className="docSymImg" alt="" />
                      </div>
                    </div>
                    <div className="modalBody">
                      <div className="detailsFill">
                        <div className="labelTxt">
                          <label className="widthFixed">Customer Name:</label>
                        </div>
                        <input
                          style={{ width: "79%" }}
                          type="text"
                          value={Orderprescription.customer_name}
                          className="form-control formSolution"
                          disabled
                        />
                      </div>
                      <div className="detailsFill">
                        <div className="labelTxt">
                          <label className="widthFixed">Patient Name:</label>
                        </div>
                        <input
                          type="text"
                          value={Orderprescription.animal_name}
                          className="form-control formSolution"
                          disabled
                        />
                      </div>
                      <div className="addressFlex">
                        <div className="labelTxt">
                          <label className="keyQuestion">Address:</label>
                        </div>
                        <input
                          type="text"
                          value={Orderprescription.customer_address}
                          className="form-control formSolution"
                          disabled
                        />
                      </div>
                      <div className="dateNdAgeFlex">
                        <div className="ageBox">
                          <label className="ageLab">Age:</label>
                          <input
                            type="text"
                            value={Orderprescription.animal_age}
                            className="form-control formSolution"
                            disabled
                          />
                        </div>
                        <div className="dateBox">
                          <label className="ageLab">Date:</label>
                          <input
                            type="text"
                            value={moment(Orderprescription.date).format(
                              "DD-MM-YYYY"
                            )}
                            className="form-control formSolution"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="addressFlex">
                        <div className="labelTxt">
                          <label className="keyQuestion">Symptoms:</label>
                        </div>
                        <input
                          type="text"
                          value={Orderprescription.appointment_desc}
                          className="form-control formSolution"
                          disabled
                        />
                      </div>
                      <div className="tableAndLandingBg">
                        <div className="backGroundPic"></div>
                        <div className="innerTable">
                          <table className="modalTable">
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Prescription</th>
                                <th>Quantity</th>
                                <th>Dose</th>
                              </tr>
                            </thead>
                            <tbody>
                              {OrderprescriptionTable &&
                              OrderprescriptionTable.length > 0 ? (
                                OrderprescriptionTable.map((item, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>{item.prescription}</td>
                                      <td>{item.quantity}</td>
                                      <td>{item.dose}</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="signatureContent">
                        <div className="sigBox">
                          <label
                            htmlFor="sigNatureFor"
                            className="signaturePad"
                          >
                            <img
                              src={Orderprescription.doctor_signature}
                              alt=""
                            />
                            <input
                              type="file"
                              id="sigNatureFor"
                              className="d-none"
                            />
                          </label>
                          <label className="belowTxt">SIGNATURE</label>
                        </div>
                      </div>
                    </div>
                    <div className="belowDetails">
                      <div className="imgWrapper">
                        <div className="row heightAuto">
                          <div className="col-lg-3 col-md-6 p-0 d-flex justify-content-end align-items-center">
                            <div className="txt">
                              <div className="innerTxt">Contact</div>
                            </div>
                          </div>
                          <div className="col-lg-9 col-md-12 p-0">
                            <div className="allCOntact">
                              <div className="emailAndCall">
                                <div className="callNnumber">
                                  <img src={CallIcon} alt="" />
                                  <label className="numberInn">
                                    {/* {Orderprescription.doctor_contact_number} */}
                                    +91 9509932234
                                  </label>
                                </div>
                                <div className="emialInn">
                                  <img src={EmailIcon} alt="" />
                                  <label className="numberInn">
                                    {/* {Orderprescription.doctor_email} */}
                                    info@animalsapp.in
                                  </label>
                                </div>
                              </div>
                              <div className="locationInn">
                                <img src={Location} alt="" />
                                <label className="locationTxt">
                                  {/* {Orderprescription.doctor_clinic_address} */}
                                  Animals App, Animalsomatic clinic Near ravi
                                  steel, Kamde ratu road, Ranchi, Jharkand,
                                  India 835222
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </motion.div>
          )}
        </InfiniteScroll>
      </>
    </>
  );
};

export default Order;
