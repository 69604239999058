import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import edu_icon from "../../assets/images/icons/edu_icon.svg";
import exp_icon from "../../assets/images/icons/exp_icon.svg";
import regi_icon from "../../assets/images/icons/regi_icon.svg";
import sign_icon from "../../assets/images/icons/sign_icon.svg";
import clinic_img_1 from "../../assets/images/clinic_img_1.svg";
import clinic_img_2 from "../../assets/images/clinic_img_2.svg";
import clinic_img_3 from "../../assets/images/clinic_img_3.svg";
import clinic_img_4 from "../../assets/images/clinic_img_4.svg";
import Service_icon from "../../assets/images/icons/Service_icon.svg";
import age_icon from "../../assets/images/icons/age_icon.svg";
import gender_icon from "../../assets/images/icons/gender_icon.svg";
import clinic_icon from "../../assets/images/icons/clinic_icon.svg";
import img_preview_delete_icon from "../../assets/images/icons/img_preview_delete_icon.svg";
import clinic_info_img from "../../assets/images/clinic_info_img.svg";
import { DarkButton } from "../commonComponents/commonComp";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const ClinicInfo = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState();
  const [signature, setSignature] = useState();
  const [deleteImg, setDeleteImg] = useState(true);

  function handleFileInput(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function handleFileInput2(e) {
    setSignature(URL.createObjectURL(e.target.files[0]));
    setDeleteImg(true);
  }

  const handleDeleteImg = () => {
    setDeleteImg(!deleteImg);
  };

  const options = [
    {
      value: "OPD (Allopathy & Homeopathy)",
      label: "OPD (Allopathy & Homeopathy)",
    },
    { value: "Dental facility", label: "Dental facility" },
    { value: "Ward/ Indoor", label: "Ward/ Indoor" },
    { value: "Minor OT/ Dressing Room", label: "Minor OT/ Dressing Room" },
    { value: "Laboratory services", label: "Laboratory services" },
  ];

  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.value);
  };
  // const [selectedOption, setSelectedOption] = useState("");

  // const handleChange1 = (selectedOption) => {
  //   setSelectedOption(selectedOption.value);
  // };

  return (
    <>
      <div className="authMain">
        <div className="row">
          <div className="col-md-6 col-lg-6">
           <img src={logo} alt="" className="login_img"/>
            <div className="signinForm signUp general_info">
              <h1 className="signInTxt mb-5">Clinic Information</h1>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Clinic Name
                </label>
                <img src={clinic_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter your clinic name"
                />
              </div>
              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Facilities
                </label>
                <Select
                  className=""
                  isMulti
                  options={options}
                  // onChange={handleChange}
                  onChange={(e) => {
                    setSelected(e);
                  }}
                  autoFocus={true}
                  // placeholder={"Select Languages..."}
                  placeholder={
                    <div className="select-placeholder-text">
                      Select Languages...
                    </div>
                  }
                />
                <img src={exp_icon} className="mailImg" alt="" />
                <div className="result">
                  {selected?.map((item, i) => {
                    return <p></p>;
                  })}
                </div>
              </div>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Address
                </label>
                <img src={clinic_icon} className="mailImg" alt="" />
                <input
                  type="text"
                  className="form-control formInput"
                  placeholder="Enter clinic address"
                />
              </div>

              <div className="inputBox">
                <label htmlFor="" className="emailLabel">
                  Clinic Photos
                </label>
                <div className="file-input">
                  <input
                    type="file"
                    name="file-input"
                    id="file-input2"
                    className="file-input__input"
                    onChange={handleFileInput2}
                    multiple
                  />
                  <img src={sign_icon} className="mailImg2" alt="" />
                  <label className="file-input__label2" for="file-input2">
                    Upload clinic photos
                  </label>
                </div>
              </div>
              {deleteImg && (
                <>
                  <div className={signature ? "sign_preview" : "d-none"}>
                    <img src={signature} alt="" />
                    <span>
                      <img
                        src={img_preview_delete_icon}
                        className="delete_icon"
                        onClick={handleDeleteImg}
                        alt=""
                      />
                    </span>
                  </div>
                </>
              )}
              <div className="inputBox">
                <div className="clinic_images_wrapper">
                  <div className="img_item">
                    <img src={clinic_img_1} />
                    <span>
                      <img src={img_preview_delete_icon} />
                    </span>
                  </div>
                  <div className="img_item">
                  <img src={clinic_img_2} />
                    <span>
                      <img src={img_preview_delete_icon} />
                    </span>
                  </div>
                  <div className="img_item">
                  <img src={clinic_img_3} />
                    <span>
                      <img src={img_preview_delete_icon} />
                    </span>
                  </div>
                  <div className="img_item">
                    <div className="overlay"></div>
                  <img src={clinic_img_4} />
                    <span className="center_text">
                        +4
                    </span>
                  </div>
                </div>
              </div>


              <div className="login btn-width-100">
                <DarkButton
                  onClick={()=>navigate('/GeneralInfo')}
                  classbtn={
                    "cx-btn-light me-3  btn-width-45 btn-height font-17 mb-5"
                  }
                  btn={"Back"}
                />
                <DarkButton
                  onClick={()=>navigate('/UploadDocs')}
                  classbtn={"cx-btn-dark  btn-height btn-width-50 font-17 mb-5"}
                  btn={"Save & Proceed"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div className="landingImg">
              <img src={clinic_info_img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinicInfo;
