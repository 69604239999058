import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import email_icon from "../../assets/images/icons/email.svg";
import user_icon from "../../assets/images/icons/user_icon.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import telephone from "../../assets/images/icons/telephone.svg";
import tele_icon from "../../assets/images/icons/tele_icon.svg";
import upload_docs_icon from "../../assets/images/icons/upload_docs_icon.svg";
import login from "../../assets/images/login.svg";
import upload_remove from "../../assets/images/icons/upload_remove.svg";
import delete_upload from "../../assets/images/icons/delete_upload.svg";
import upload_success from "../../assets/images/icons/upload_success.svg";
import upload_doc_icon from "../../assets/images/icons/upload_doc_icon.svg";
import upload_icon from "../../assets/images/icons/upload_icon.svg";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import location_icon from "../../assets/images/icons/location_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import Lock from "../../assets/images/lockIcon.svg";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
  PostCallWithErrorResponse,
  multipartPostCall,
  simpleGetCall,
  simplePostAuthCallNoTokan,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { AppContext } from "../../context/AppContext";
import AutocompleteGoogleInput from "../../sharedComponent/AutocompleteGoogleInput";

const SignUp = () => {
  const { validNumber } = useContext(AppContext);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [validated, setValidated] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileNameTwo, setFileNameTwo] = useState("");
  const [profileName, setProfileName] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    address: "",
    aadhar_card: "",
    aadhar_back: "",
    email: "",
    phone: null,
    password: null,
    otp: null,
    is_agree: false,
    hash: "",
    profilePath: "",
  });
  console.log("formData,", formData)
  const handleChange = (e) => {
    if (e.target.name === "aadhar_card") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
      setFileName(e.target.files[0]);
    } else setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleChange2 = (e) => {
    if (e.target.name === "aadhar_back") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
      setFileNameTwo(e.target.files[0]);
    } else setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleProfile = (e) => {
    if (e.target.name === "profilePath") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
      setProfileName(e.target.files[0]);
    } else setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const changeEveVaue = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [seconds, setSeconds] = useState(60);
  const [firstClick, setFirstClick] = useState(false);

  const startTimer = () => {
    // setSeconds(60);
    if (seconds > 0) {
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  // handleOtp api

  const handleOtp = () => {
    if (formData?.phone?.length !== 10) {
      notifyError("Please enter valid mobile number");
      return;
    }
    let payload = {
      phone_number: formData?.phone,
    };
    if (formData.phone !== null) {
      simplePostAuthCallNoTokan(ApiConfig.GET_OTP, JSON.stringify(payload))
        .then((res) => {
          if (res.success === true) {
            startTimer();
            notifySuccess(res?.message);
            setFirstClick(true);
            setFormData({
              ...formData,
              hash: res.hash,
            });
          } else {
            notifyError(res?.message);
            setFirstClick(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      notifyError("Please Enter Mobile Number");
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      registerUser(event);
    }
    setValidated(true);
  };

  const registerUser = (event) => {
    event.preventDefault();
    const payloadData = new FormData();

    payloadData.append("first_name", formData.first_name);
    payloadData.append("last_name", formData.last_name);
    payloadData.append("address", formData.address);
    payloadData.append("email", formData.email);
    payloadData.append("phone", formData.phone);
    payloadData.append("password", formData.password);
    payloadData.append("otp", formData.otp);
    payloadData.append("aadhar_card", formData.aadhar_card);
    payloadData.append("aadhar_card_back", formData.aadhar_back);
    payloadData.append("is_agree", formData.is_agree);
    payloadData.append("hash", formData.hash);
    payloadData.append("profilePath", formData.profilePath);

    multipartPostCall(ApiConfig.REGISTER_USER, payloadData)
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          localStorage.setItem("pharmacy_id", res.data.pharmacy_id);
          navigate("/BankDetails");
        } else {
          notifyError(res.message);
        }
      })
      .catch((error) => {
        console.log("error =>", error);
      });
  };


  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={logo} alt="" className="login_img" />
              <div className="sign_in_wrapper">
                <div className="signinForm signUp">
                  <h1 className="signInTxt">Sign up</h1>
                  <p className="signInDesc">
                    If you already have an account register <br /> You can
                    <Link className="link_common" to="/">
                      Login here !
                    </Link>
                  </p>
                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom01"
                  >
                    <Form.Label htmlFor="" className="emailLabel">
                      First Name
                    </Form.Label>
                    <img src={user_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={changeEveVaue}
                      className="form-control formInput"
                      placeholder="Enter your First name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide First Name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom02"
                  >
                    <Form.Label htmlFor="" className="emailLabel">
                      Last Name
                    </Form.Label>
                    <img src={user_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="last_name"
                      value={formData.last_name}
                      onChange={changeEveVaue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your Last name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Last Name
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom03"
                  >
                    <Form.Label htmlFor="" className="emailLabel">
                      Address
                    </Form.Label>
                    <img src={location_icon} className="mailImg" alt="" />
                    {/* <AutocompleteGoogleInput
                      value={formData.address}
                      required={true}
                      className={"form-control formInput"}
                      placeholder={"Enter your address"}
                      // onPlaceSelect={handlePlaceSelect}
                      onPlaceSelect={(
                        { lat, lng, address },
                        event,
                        index,
                        name
                      ) => {
                        const locationData = { lat, lng, address };
                        setFormData({
                          ...formData,
                          address: locationData.address,
                          lat: locationData.lat,
                          lng: locationData.lng,
                        });
                      }}
                    /> */}
                    <Form.Control
                      required
                      name="address"
                      value={formData.address}
                      onChange={changeEveVaue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter address here..."
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Address
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom04"
                  >
                    <Form.Label htmlFor="" className="emailLabel">
                      Email
                    </Form.Label>
                    <img src={email_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="email"
                      value={formData.email}
                      onChange={changeEveVaue}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your Email"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Email
                    </Form.Control.Feedback>
                  </Form.Group>
                  
                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom04"
                  >
                    <Form.Label htmlFor="" className="emailLabel">
                      Password
                    </Form.Label>
                    <img src={Lock} className="mailImg" alt="" />
                    <div className="eyePosition" onClick={togglePassword}>
                      {passwordType === "password" ? (
                        <img src={ic_show} className="absolutEye" alt="" />
                      ) : (
                        <img src={ic_hide_eyes} className="absolutEye" alt="" />
                      )}
                    </div>
                    <Form.Control
                      required
                      name="password"
                      value={formData.password}
                      onChange={changeEveVaue}
                      type={passwordType}
                      className="form-control formInput"
                      placeholder="Enter your Password"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Password
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom05"
                  >
                    {seconds == 60 && (
                      <button
                        type="button"
                        className="otpbtn"
                        onClick={() => {
                          handleOtp();
                        }}
                      >
                        Request OTP
                      </button>
                    )}
                    {seconds !== 60 && (
                      <button
                        type="button"
                        className="resendotpbtn"
                        onClick={() => {
                          if (seconds == 0) {
                            handleOtp();
                          }
                        }}
                      >
                        Re-send OTP ({seconds})
                      </button>
                    )}
                    {/* <>
                      {firstClick ? null : (
                        <button
                          type="button"
                          className="otpbtn"
                          onClick={() => {
                            handleOtp();
                          }}
                        >
                          Request OTP
                        </button>
                      )}
                    </> */}
                    {/* {seconds == 60 ? (
                      <button className="resendotpbtnGreen" onClick={handleOtp}>
                        Re send OTP 
                      </button>
                    ) : (
                      <button className="resendotpbtn" onClick={handleOtp}>
                        Re send OTP ({seconds})
                      </button>
                    )} */}

                    <Form.Label htmlFor="" className="emailLabel">
                      Phone Number
                    </Form.Label>
                    <img src={telephone} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="phone"
                      value={formData.phone}
                      onChange={(e) => {
                        if (
                          validNumber.test(e.target.value) &&
                          e.target.value.toString().length < 14
                        ) {
                          changeEveVaue(e);
                        }
                      }}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your phone number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide Phone Number
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="inputBox"
                    controlId="validationCustom06"
                  >
                    <Form.Label htmlFor="" className="emailLabel">
                      OTP
                    </Form.Label>
                    <img src={otp_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      type="number"
                      name="otp"
                      value={formData.otp}
                      onChange={(e) => {
                        if (validNumber.test(e.target.value)) {
                          changeEveVaue(e);
                        }
                      }}
                      className="form-control formInput"
                      placeholder="Enter OTP here..."
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide OTP Sent
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="inputBox" controlId="validationCustom07">
                    <label htmlFor="" className="emailLabel">
                      Profile Photo
                    </label>
                    <div className="file-input">
                      <input
                        required
                        type="file"
                        name="profilePath"
                        id="file-input3"
                        className="file-input__input"
                        onChange={handleProfile}
                      />
                      <img src={upload_docs_icon} className="mailImg2" alt="" />
                      <label className="file-input__label2" for="file-input3">
                        {profileName !== ""
                          ? profileName?.name
                          : "Upload Profile Photo"}
                      </label>
                    </div>
                    <p></p>
                  </div>
                  <div className="inputBox" controlId="validationCustom07">
                    <label htmlFor="" className="emailLabel">
                      Aadhar Card Front
                    </label>
                    <div className="file-input">
                      <input
                        required
                        type="file"
                        name="aadhar_card"
                        id="file-input2"
                        className="file-input__input"
                        onChange={handleChange}
                      />
                      <img src={upload_docs_icon} className="mailImg2" alt="" />
                      <label className="file-input__label2" for="file-input2">
                        {fileName !== ""
                          ? fileName?.name
                          : "Upload Aadhar Card Front"}
                      </label>
                    </div>
                    <p></p>
                  </div>
                  {fileName && (
                    <>
                      {fileName === "" ? (
                        <div className="uploading_item">
                          <img src={upload_doc_icon} />
                          <div className="progress_wrapper">
                            <p className="doc_name">{fileName.name}</p>
                            <progress value="32" max="100" className="blue">
                              32
                            </progress>
                          </div>
                          <img src={delete_upload} />
                        </div>
                      ) : (
                        <div className="uploading_item mb-2">
                          <img src={upload_doc_icon} />
                          <div className="progress_wrapper">
                            <p className="doc_name">{fileName.name}</p>
                            {/* <progress value="32" max="100" className="blue">32</progress> */}
                          </div>
                          <img src={upload_success} />
                          <img
                            src={upload_remove}
                            onClick={() => setFileName("")}
                            className="remove"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="inputBox" controlId="validationCustom07">
                    <label htmlFor="" className="emailLabel">
                      Aadhar Card Back
                    </label>
                    <div className="file-input">
                      <input
                        required
                        type="file"
                        name="aadhar_back"
                        id="file-input4"
                        className="file-input__input"
                        onChange={handleChange2}
                      />
                      <img src={upload_docs_icon} className="mailImg2" alt="" />
                      <label className="file-input__label2" for="file-input4">
                        {fileNameTwo !== ""
                          ? fileNameTwo?.name
                          : "Upload Aadhar Card Back"}
                      </label>
                    </div>
                    <p></p>
                  </div>
                  {fileNameTwo && (
                    <>
                      {fileNameTwo === "" ? (
                        <div className="uploading_item">
                          <img src={upload_doc_icon} />
                          <div className="progress_wrapper">
                            <p className="doc_name">{fileNameTwo.name}</p>
                            <progress value="32" max="100" className="blue">
                              32
                            </progress>
                          </div>
                          <img src={delete_upload} />
                        </div>
                      ) : (
                        <div className="uploading_item mb-2">
                          <img src={upload_doc_icon} />
                          <div className="progress_wrapper">
                            <p className="doc_name">{fileNameTwo.name}</p>
                            {/* <progress value="32" max="100" className="blue">32</progress> */}
                          </div>
                          <img src={upload_success} />
                          <img
                            src={upload_remove}
                            onClick={() => setFileNameTwo("")}
                            className="remove"
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div className="inputBox">
                    <div className="belowChecks">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="is_agree"
                          checked={formData.is_agree}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              is_agree: e.target.checked,
                            })
                          }
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          I agree with
                          <span className="link_common" to="#">
                            terms and conditions
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="login btn-width-100">
                    <DarkButton
                      type="submit"
                      classbtn={
                        "cx-btn-dark btn-width-100 btn-height font-17 mb-4"
                      }
                      btn={"Register"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg2">
                <p className="tele">
                  {" "}
                  <img src={tele_icon} alt="" /> +91 9509932234
                </p>
                <img src={login} alt="" className="auth_main_img img-fluid" />
                <div className="text_wrapper">
                  <h2 className="tw_title">Sign in to Pharmacy App</h2>
                  <p className="tw-sub_title">Lets Start the Journey...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default SignUp;
