import ApiConfig, { BASE_URL } from "../api/ApiConfig";
import { simpleGetCallWithToken } from "../api/ApiServices";
import { AppContext } from "./AppContext";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import useSound from "use-sound";
import fanfareSfx from "../assets/sound/Notifica.mp3";
import { notificationMsg, notifySuccess } from "../sharedComponent/notify";
const AppState = (props) => {
  const [play, { stop }] = useSound(fanfareSfx);

  const [sidebar, setSidebar] = useState(false);
  const [Dark, setDark] = useState("lightMode");
  const [Prescription, setPrescription] = useState(false);
  const [userData, setUserData] = useState({
    pharmacy_id: localStorage.getItem("pharmacy_id")
      ? localStorage.getItem("pharmacy_id")
      : null,
    first_name: localStorage.getItem("first_name")
      ? localStorage.getItem("first_name")
      : null,
    last_name: localStorage.getItem("last_name")
      ? localStorage.getItem("last_name")
      : null,
    address: localStorage.getItem("address")
      ? localStorage.getItem("address")
      : null,
    email: localStorage.getItem("email") ? localStorage.getItem("email") : null,
    phone_number: localStorage.getItem("phone_number")
      ? localStorage.getItem("phone_number")
      : null,
    isLoggedIn: localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : null,
    pharmacy_shop_id: localStorage.getItem("pharmacy_shop_id")
      ? localStorage.getItem("pharmacy_shop_id")
      : null,
    pharmacy_shop_name: localStorage.getItem("pharmacy_shop_name")
      ? localStorage.getItem("pharmacy_shop_name")
      : null,
    type_of_pharmacy_shop_id: localStorage.getItem("type_of_pharmacy_shop_id")
      ? localStorage.getItem("type_of_pharmacy_shop_id")
      : null,
    user_id: localStorage.getItem("user_id")
      ? localStorage.getItem("user_id")
      : null,
    pharmacy_shop_address: localStorage.getItem("pharmacy_shop_address")
      ? localStorage.getItem("pharmacy_shop_address")
      : null,
    pharmacy_shop_license_number: localStorage.getItem(
      "pharmacy_shop_license_number"
    )
      ? localStorage.getItem("pharmacy_shop_license_number")
      : null,
    profile_image_path: localStorage.getItem("profile_image_path")
      ? localStorage.getItem("profile_image_path")
      : null,
    user_status: localStorage.getItem("user_status")
      ? JSON.parse(localStorage.getItem("user_status"))
      : null,
    is_active: localStorage.getItem("is_active")
      ? localStorage.getItem("is_active")
      : null,
  });
  const [loading, setLoading] = useState(false);
  const [updateImg, setUpdateImg] = useState({
    pharmacy_id: userData.pharmacy_id,
    profilePath: "",
  });

  const [profileData, setProfileData] = useState({});

  const [notificationCount, setNotificationCount] = useState(0);
  const [noticationList, setnoticationList] = useState([]);

  useEffect(() => {
    getProfileDet();
  }, [userData.pharmacy_id]);

  ///// Api

  const getProfileDet = () => {
    setLoading(true);
    simpleGetCallWithToken(
      ApiConfig.GET_USER_PROFILE_DETAILS + userData.pharmacy_id
    )
      .then((res) => {
        if (res.success === true) {
          setProfileData(res?.data);

          setLoading(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  let option = {
    timeout: 20000,
    query: {
      UID: userData.user_id,
    },
  };

  const [socket, setSocket] = useState();
  useEffect(() => {
    if (userData) {
      setSocket(
        io(`${BASE_URL}?UID=${userData.user_id}`, option)
      );
    }
  }, [userData]);

  const handleMouseEnter = () => {
    play();
  };

  const [audio, setAudio] = useState(new Audio(fanfareSfx));
  
  useEffect(() => {
    socket &&
      socket.on("notification", (data) => {
        handleMouseEnter();
        notificationMsg(data.text);
        if(data.text?.toLowerCase().includes("order")){
          audio.currentTime = 0;
          audio.play();
        }
        setNotificationCount((prev) => prev + 1);
      });

    return () => {
      socket && socket.off("notification");
    };
  }, [socket]);

  var validNumber = new RegExp(/^\d*\.?\d*$/);
  return (
    <div>
      <AppContext.Provider
        value={{
          socket,
          sidebar,
          setSidebar,
          Dark,
          setDark,
          Prescription,
          setPrescription,
          userData,
          setUserData,
          loading,
          setLoading,
          updateImg,
          setUpdateImg,
          profileData,
          setProfileData,
          getProfileDet,
          notificationCount,
          setNotificationCount,
          validNumber,
          noticationList,
          setnoticationList,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </div>
  );
};

export default AppState;
