import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import teleCall from "../../assets/images/icons/telephone.svg";
import telephone1 from '../../assets/images/telephone1.svg'
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import mailBox from "../../assets/images/icons/emailSvg.svg";
import passBox from "../../assets/images/icons/lock.svg";
import eye from "../../assets/images/icons/eye.svg";
import login_img from "../../assets/images/login_img.png";
import logo from "../../assets/images/logo.svg";
import fb from "../../assets/images/fb.svg";
import apple from "../../assets/images/apple.svg";
import google from "../../assets/images/google.svg";
import tele_icon from "../../assets/images/icons/tele_icon.svg";
import login from "../../assets/images/login.svg";
import ic_hide_eyes from "../../assets/images/ic_hide_eyes.svg";
import ic_show from "../../assets/images/ic_show_eyes.svg";
import { Link, Route, useNavigate } from "react-router-dom";
import { DarkButton } from "../commonComponents/commonComp";
import Form from "react-bootstrap/Form";
import {
  PostCallWithErrorResponse,
  multipartPostCall,
  postMultipartWithAuthCall,
  postWithAuthCall,
  simplePostAuthCallNoTokan,
  simplePostCall,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import AppState from "../../context/AppState";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import ic_left_green_arrow from '../../assets/images/ic_left_green_arrow.svg'

const Login = ({ setLoggedIn }) => {
 
  const { userData, setUserData, getProfileDet, validNumber } =
    useContext(AppContext);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [isLoginWithOTP, setIsLoginWithOTP] = useState(false);
  const [loginOTPData, setLoginOTPData] = useState({
    phone_number: "",
    otp: "",
  });
 
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const changeEveVaue = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      loginUser(event);
    }
    setValidated(true);
  };

  const loginUser = (event) => {
    event.preventDefault();
    let payload = isLoginWithOTP ? loginOTPData :  {
      email: loginData?.email,
      password: loginData?.password,
    };
    const API_URL = isLoginWithOTP ? ApiConfig.LOGIN_WITH_OTP : ApiConfig.LOGIN_USER
    simplePostAuthCallNoTokan(API_URL, JSON.stringify(payload))
      .then((res) => {
        if (res.success === true) {
          localStorage.setItem("Token", res.token);
          localStorage.setItem("pharmacy_id", res.data.pharmacy_id);
          localStorage.setItem("first_name", res.data.first_name);
          localStorage.setItem("last_name", res.data.last_name);
          localStorage.setItem("address", res.data.address);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("phone_number", res.data.phone_number);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem(
            "pharmacy_id",
            res.getUserDetails[0].pharmacy_id
          );
          localStorage.setItem(
            "pharmacy_shop_id",
            res.getUserDetails[0].pharmacy_shop_id
          );
          localStorage.setItem(
            "type_of_pharmacy_shop_id",
            res.getUserDetails[0].type_of_pharmacy_shop_id
          );
          localStorage.setItem(
            "pharmacy_shop_address",
            res.getUserDetails[0].pharmacy_shop_address
          );
          localStorage.setItem("user_id", res.getUserDetails[0].user_id);
          localStorage.setItem(
            "pharmacy_shop_license_number",
            res.getUserDetails[0].pharmacy_shop_license_number
          );
          localStorage.setItem("is_active", res.getUserDetails[0].is_active);
          localStorage.setItem(
            "profile_image_path",
            res.getUserDetails[0]?.profile_image_path
          );
          localStorage.setItem(
            "user_status",
            JSON.stringify(res.getUserDetails[0]?.json_agg)
          );
          notifySuccess(res.message);
          setUserData({
            pharmacy_id: res.data.pharmacy_id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            address: res.data.address,
            email: res.data.email,
            phone_number: res.data.phone_number,
            isLoggedIn: true,
            pharmacy_shop_id: res.getUserDetails[0].pharmacy_shop_id,
            pharmacy_shop_name: res.getUserDetails[0].pharmacy_shop_name,
            type_of_pharmacy_shop_id:
              res.getUserDetails[0].type_of_pharmacy_shop_id,
            pharmacy_shop_address: res.getUserDetails[0].pharmacy_shop_address,
            user_id: res.getUserDetails[0].user_id,
            pharmacy_shop_license_number:
              res.getUserDetails[0].pharmacy_shop_license_number,
            profile_image_path: res.getUserDetails[0].profile_image_path,
            is_active: res.getUserDetails[0].is_active,
          });
          getProfileDet();
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };

  const startTimer = () => {
    // setSeconds(60);
    if (seconds > 0) {
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  const requestOTP = async () => {
    if (loginOTPData?.phone_number?.length !== 10) {
      notifyError("Please enter valid mobile number");
      return;
    }
    try {
      const res = await simplePostCall(
        ApiConfig.SEND_OTP_FOR_LOGIN,
        JSON.stringify({ phone_number: loginOTPData?.phone_number })
      );
      const { success } = res
      if (success) {
        notifySuccess("OTP sent to your mobile number");
        startTimer();
      }
    } catch (error) {
      console.log("Catch error of request OTP API", error);
    }
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={logo} alt="" className="login_img" />
              <div className="signinForm">
                <h1 className="signInTxt">Sign in</h1>
                <p className="signInDesc">
                  If you don't have an account registered <br /> You can
                  <Link className="link_common" to="/SignUp">
                    {" "}
                    Register here !
                  </Link>
                </p>
                {!isLoginWithOTP ? (
                  <>
                    <Form.Group
                      className="inputBox"
                      controlId="validationCustom01"
                    >
                      <Form.Label htmlFor="" className="emailLabel">
                        Email
                      </Form.Label>
                      <img src={mailBox} className="mailImg" alt="" />
                      <Form.Control
                        required
                        type="text"
                        name="email"
                        value={loginData.email}
                        onChange={changeEveVaue}
                        className="form-control formInput"
                        placeholder="Enter your email address"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Email
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="inputBox"
                      controlId="validationCustom02"
                    >
                      <Form.Label htmlFor="" className="emailLabel">
                        Password
                      </Form.Label>
                      <img src={passBox} className="mailImg" alt="" />
                      <div className="eyePosition" onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <img src={ic_show} className="absolutEye" alt="" />
                        ) : (
                          <img
                            src={ic_hide_eyes}
                            className="absolutEye"
                            alt=""
                          />
                        )}
                      </div>
                      {/* <img src={eye} className="eyeImg" alt="" /> */}
                      <Form.Control
                        required
                        type={passwordType}
                        name="password"
                        value={loginData.password}
                        onChange={changeEveVaue}
                        className="form-control formInput"
                        placeholder="Enter your Password"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Password
                      </Form.Control.Feedback>
                      <div className="belowChecks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="forgetPass">
                          <Link to="/ForgotPass" className="">
                            Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </Form.Group>
                  </>
                ) : (
                  <>
                  <div
                  style={{marginBottom : "20px", cursor : "pointer"}}
                  onClick={() => setIsLoginWithOTP(false)}
                  ><img src={ic_left_green_arrow} alt="im" /></div>
                    <Form.Group
                      className="inputBox"
                      controlId="validationCustom01"
                    >
                      <div className="mobile_label_with_request_otp">
                        <Form.Label htmlFor="" className="emailLabel">
                          Mobile Number
                        </Form.Label>
                        {seconds == 60 && (
                          <button
                            type="button"
                            className="requestBtn"
                            // disabled={!forgotData?.phone_number ? true : false}
                            onClick={requestOTP}
                          >
                            Request OTP
                          </button>
                        )}
                      </div>

                      <img src={telephone1} className="mailImg" alt="" />
                      <Form.Control
                        required
                        type="text"
                        name="phone_number"
                        value={loginOTPData?.phone_number}
                        onChange={(e) => {
                          if (validNumber.test(e.target.value)) {
                            setLoginOTPData({
                              ...loginOTPData,
                              phone_number: e.target.value,
                            });
                          }
                        }}
                        className="form-control formInput"
                        placeholder="Enter your mobile number"
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your mobile number
                      </Form.Control.Feedback>
                      {seconds !== 60 && (
                        <button
                          type="button"
                          className="resendotpbtn"
                          onClick={() => {
                            if (seconds == 0) {
                              // handleOtp();
                              requestOTP()
                            }
                          }}
                        >
                          Re-send OTP ({seconds})
                        </button>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="inputBox"
                      controlId="validationCustom02"
                    >
                      <Form.Label htmlFor="" className="emailLabel">
                        OTP
                      </Form.Label>
                      <img src={passBox} className="mailImg" alt="" />

                      {/* <img src={eye} className="eyeImg" alt="" /> */}
                      <Form.Control
                        required
                        type="text"
                        className="form-control formInput"
                        placeholder="Enter your Password"
                        value={loginOTPData?.otp}
                        onChange={(e) => {
                          if (validNumber.test(e.target.value)) {
                            setLoginOTPData({
                              ...loginOTPData,
                              otp: e.target.value,
                            });
                          }
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please Provide Password
                      </Form.Control.Feedback>
                      <div className="belowChecks">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            for="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>
                        <div className="forgetPass">
                          <Link to="/ForgotPass" className="">
                            Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </Form.Group>
                  </>
                )}

                <div className="login btn-width-100">
                  <DarkButton
                    type="submit"
                    classbtn={"cx-btn-dark btn-width-100 btn-height font-17"}
                    btn={"Login"}
                  />
                </div>
                {!isLoginWithOTP && (
                  <div
                    style={{
                      color: "#21c896",
                      marginTop: "30px",
                      cursor: "pointer",
                      marginLeft : "40%"
                    }}
                    onClick={() => setIsLoginWithOTP(true)}
                  >
                    or continue with OTP
                  </div>
                )}

                {/* <div className="social-account-login">
                  <label htmlFor="">or continue with</label>
                  <div className="social-btns">
                    <Link className="fb">
                      <img src={fb} alt="" />
                    </Link>
                    <Link className="fb">
                      <img src={apple} alt="" />
                    </Link>
                    <Link className="fb">
                      <img src={google} alt="" />
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg2">
                <p className="tele">
                  {" "}
                  <img src={tele_icon} alt="" /> +91 9509932234
                </p>
                <img src={login} alt="" className="auth_main_img" />
                <div className="text_wrapper">
                  <h2 className="tw_title">Sign in to Pharmacy App</h2>
                  <p className="tw-sub_title">Lets Start the Journey...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default Login;
