import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import InventoryBack from "../../assets/images/pet-shop-img/InventoryBack.svg";
import Star from "../../assets/images/pet-shop-img/Star.svg";
import ic_filter from "../../assets/images/ic_filter.svg";
import Add from "../../assets/images/pet-shop-img/Add_icon.svg";
import Belt_img from "../../assets/images/pet-shop-img/Belt_img.svg";
import InventoryProduc2 from "../../assets/images/pet-shop-img/InvenotyrBack2.svg";
import Form from "react-bootstrap/Form";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import {
  getWithAuthCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePutAuthCall,
} from "../../api/ApiServices";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../sharedComponent/Loader";
import Select from "react-select";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";

const Inventory = () => {
  const [productCat, setProductCat] = useState("All");
  const [ProductNameFilter, setProductNameFilter] = useState("");
  const [BrandFilter, setProductBrandFilter] = useState("");
  const [ProductBrandFilter, setProductCategoryFilter] = useState("");
  const [last_page, setlast_page] = useState(false);
  const [TotalData, setTotalData] = useState(0);
  const [Inventory, setInventory] = useState([]);
  const [InventoryCategory, setInventoryCategory] = useState([]);
  //   console.log(Inventory?.product_images[0].product_image_path);
  const [page, setPage] = useState(1);
  const [PageCategory, setPageCategory] = useState(1);
  const [FacilitiesType, setFacilitiesType] = useState([]);
  const [ProductType, setProductType] = useState([]);
  const [SelectedProduct, setSelectedProduct] = useState({});
  const { setLoading, userData, loading, validNumber } = useContext(AppContext);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      setLoading(true);
      getDetails(page);
    }, 1000);

    return () => clearTimeout(getData);
  }, [BrandFilter]);

  useEffect(() => {
    if (ProductNameFilter === "") {
      setLoading(true);
      getDetails(page);
    } else {
      getDetails(page);
    }
  }, [ProductNameFilter]);

  const getDetails = (currentPage) => {
    simpleGetCallWithToken(
      ApiConfig.INVENTORY_PRODUCT_LIST +
        userData.pharmacy_shop_id +
        "&product_name=" +
        ProductNameFilter +
        "&product_type=" +
        BrandFilter +
        "&product_brand=" +
        BrandFilter +
        "&product_id=" +
        "" +
        "&page=" +
        currentPage
    )
      .then((data) => {
        if (data.success) {
          setLoading(false);
          let total = data?.total ? data?.total : 0;
          setTotalData(total);
          setlast_page(data.last_page);

          let coursesData = data.data ? data.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            setInventory(data?.data);
            setLoading(false);
          } else {
            setInventory([...Inventory, ...LearningData]);
            setLoading(false);
          }
        } else {
          setInventory([]);
          setlast_page(false);
          notifyError(data?.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getDetailsProduct(PageCategory);
  }, []);

  useEffect(() => {}, [SelectedProduct]);
  const getDetailsProduct = (currentPage) => {
    simpleGetCallWithToken(
      ApiConfig.INVENTORY_PRODUCT_CATEGORYLIST + userData.pharmacy_shop_id
    )
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          //   setLoading(false);
          let total = data?.total ? data?.total : 0;
          setTotalData(total);
          //   setlast_page(data.last_page);

          let coursesData = data?.data ? data?.data : [];
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              animal_name: eachteam.animal_name || "",
              phone_number: eachteam.phone_number || "",
            };
          });

          if (currentPage === 1) {
            setInventoryCategory(data?.data);
            // setLoading(false);
          } else {
            setInventoryCategory([...InventoryCategory, ...LearningData]);
            // setLoading(false);
          }
        } else {
          setInventoryCategory([]);
          setlast_page(false);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getfacilities();
    getProduct();
  }, []);

  /** enable or disable product */

  const disabledPharmacyProduct = async (
    productId,
    active,
    pharmacy_shop_id
  ) => {
    try {
      const payLoad = {
        product_id: productId,
        pharmacy_shop_id: pharmacy_shop_id,
        is_active: active,
      };

      const res = await simplePutAuthCall(
        `${ApiConfig.ENABLE_DISABLED}`,
        JSON.stringify(payLoad)
      );
      const { success, message } = res;
      if (success) {
        notifySuccess(
          active
            ? "Product enabled successfully"
            : "Product disabled successfully"
        );
        getDetails(1);
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  function getfacilities() {
    getWithAuthCall(ApiConfig.ADD_INVENTORY_DROPDOWN)
      .then((data) => {
        let Type = data?.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.product_type,
            value: grade.product_type_id,
          });
        });
        setFacilitiesType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  function getProduct() {
    simpleGetCallWithToken(ApiConfig.ADD_PRODUCT_DROPDOWN)
      .then((data) => {
        let Type = data?.data;
        var grades = [];

        Type.map((grade, index) => {
          grades.push({
            label: grade.product,
            value: grade.product_id,
            product_brand: grade.product_brand,
            product_code: grade.product_code,
            product_type: grade.product_type,
            product_rate: grade.product_rate,
            product_images: grade.product_images,
          });
        });
        setProductType(grades);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  const onPageLoad = () => {
    setPage(page + 1);
    if (last_page === false) {
      getDetails(page + 1);
    } else {
    }
  };

  function onTopicChange(selectedOption) {
    console.log("selectedOption", selectedOption);
    setSelectedProduct(selectedOption);
  }

  const addProductPost = () => {
    let newRequestBody = JSON.stringify({
      product_id: SelectedProduct.value,
      product_quantity: SelectedProduct.pharmacy_shop_product_quantity,
      pharmacy_shop_id: userData.pharmacy_shop_id,
    });
    simplePostAuthCall(ApiConfig.ADD_PRODUCT, newRequestBody)
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setProductCat("All");
          getDetails(1);
          notifySuccess(data.message);
          setSelectedProduct({
            pharmacy_shop_product_quantity: "",
          });
        } else {
          notifyError(data.message);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const EditProductPost = (id) => {
    let newRequestBody = JSON.stringify({
      product_quantity: SelectedProduct.pharmacy_shop_product_quantity,
      pharmacy_shop_product_id: id,
    });
    simplePostAuthCall(ApiConfig.PRODUCT_EDIT, newRequestBody)
      .then((data) => {
        // setClintDetails(data?.data);

        if (data.success) {
          setProductCat("All");
          getDetails(1);
          notifySuccess(data.message);
        } else {
          notifyError(data.message);
        }
        // setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  function getEditbyeDetails(id) {
    simpleGetCallWithToken(ApiConfig.EDIT_BYE_DETAILS + id)
      .then((res) => {
        setSelectedProduct(res?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <>
      <>
        <InfiniteScroll
          dataLength={Inventory?.length}
          next={onPageLoad}
          // hasMore={true}
          hasMore={Inventory.length !== TotalData}
          scrollableTarget="scroll-table"
          loader={
            <h4
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginLeft: "20px",
              }}
            ></h4>
          }
        >
          <div className="appointment-wrapper" id="cx-main">
            <div className="reportsTabs">
              <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="1"
                      onClick={() => {
                        setProductCat("All");
                      }}
                    >
                      Product List
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">Product Category</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <div className="search-filter-wrapper">
                      {productCat === "Add" || productCat === "Edit" ? (
                        <></>
                      ) : (
                        <div className="search-filter-left">
                          <div className="row">
                            <div className="col-lg-5 mb-2">
                              <input
                                type="text"
                                className="form-control serach-input1 me-4"
                                placeholder="Search by Name"
                                value={ProductNameFilter}
                                onChange={(e) => {
                                  setProductNameFilter(e.target.value);
                                }}
                              />
                            </div>
                            <div className="col-lg-5 mb-2">
                              <div className="selectBox">
                                <input
                                  type="text"
                                  className="form-control serach-input1 me-4"
                                  placeholder="Search by product type..."
                                  value={BrandFilter}
                                  onChange={(e) => {
                                    setProductBrandFilter(e.target.value);
                                    // debouncedHandleChange(e.target.value)
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-2 mb-2 d-flex justify-content-between">
                              {/* <button className="fltr-btn">
                                  <img src={ic_filter} alt="" />
                                </button> */}
                              <button
                                className="Top_Add_Btn"
                                onClick={() => {
                                  setProductCat("Add");
                                }}
                              >
                                <img src={Add} alt="" />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {productCat === "All" ? (
                      loading === true ? (
                        <Loader />
                      ) : (
                        <div className="Main_Page_Scroll mt-4">
                          <div className="row">
                            {Inventory && Inventory.length > 0 ? (
                              Inventory.map((itemlist, index) => {
                                return (
                                  <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="INventorySingle">
                                      <div className="rating">
                                        <img src={Star} alt="" />
                                        {itemlist.avg_rating === null
                                          ? 0
                                          : itemlist.avg_rating}{" "}
                                        / 5
                                      </div>

                                      <div className="InventoryImage">
                                        <img
                                          src={itemlist?.product_image_path}
                                          alt=""
                                        />
                                      </div>

                                      <div className="ProductDetails">
                                        <div className="productName">
                                          <p className="name">
                                            {itemlist.product}
                                          </p>
                                          <p className="brand">
                                            {itemlist?.product_brand}{" "}
                                            {itemlist?.product_type}
                                          </p>
                                        </div>
                                        <div className="checkSwitch">
                                          <Form.Check
                                            type="switch"
                                            checked={itemlist?.is_active}
                                            onChange={(e) => {
                                              disabledPharmacyProduct(
                                                itemlist?.product_id,
                                                e.target.checked,
                                                itemlist?.pharmacy_shop_id
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="discribtion">
                                        {itemlist.product_desc}
                                      </div>
                                      <div className="prising">
                                        <div className="QuantityDetails">
                                          <p className="Quantity">Quantity</p>
                                          <p className="QuantityCount">
                                            {
                                              itemlist.pharmacy_shop_product_quantity
                                            }
                                          </p>
                                        </div>
                                        <div className="line"></div>
                                        <div className="PriceDetails">
                                          <p className="Price">Price</p>
                                          <p className="PriceCount">
                                            {itemlist.product_rate}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="">
                                        <button
                                          className="btn-width-100 cx-btn-dark-2"
                                          onClick={() => {
                                            setProductCat("Edit");
                                            getEditbyeDetails(
                                              itemlist.pharmacy_shop_product_id
                                            );
                                          }}
                                        >
                                          Edit Product
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                          {Inventory.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "20px",
                                marginTop: "200px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any
                              Inventory?
                            </p>
                          )}
                        </div>
                      )
                    ) : null}

                    {productCat === "Edit" ? (
                      <>
                        <div className="Form_main mt-4">
                          <div className="Heading">Edit Your Project</div>
                          <div className="Scroll_With_Bottom_Btn">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Product</label>
                                  {/* <Select
                                      options={FacilitiesType}
                                      // onChange={handleChange}
                                      //   onChange={onTopicChange}
                                      autoFocus={true}
                                      // placeholder={"Select Languages..."}
                                      placeholder={
                                        <div className="select-placeholder-text">
                                          Select Category...
                                        </div>
                                      }
                                    /> */}
                                  <input
                                    type="text"
                                    placeholder="Product Name"
                                    value={SelectedProduct.product}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Category</label>

                                  <input
                                    type="text"
                                    placeholder="Product Brand"
                                    value={SelectedProduct.product_type}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Brand</label>
                                  <input
                                    type="text"
                                    placeholder="Product Brand"
                                    value={SelectedProduct.product_brand}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Code</label>
                                  <input
                                    type="text"
                                    placeholder="Product Code"
                                    value={SelectedProduct.product_code}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Quantity</label>
                                  <input
                                    type="text"
                                    placeholder="Enter Quantity"
                                    value={
                                      SelectedProduct.pharmacy_shop_product_quantity
                                    }
                                    onChange={(e) => {
                                      if (validNumber.test(e.target.value)) {
                                        setSelectedProduct({
                                          ...SelectedProduct,
                                          pharmacy_shop_product_quantity:
                                            e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Rate</label>
                                  <input
                                    type="text"
                                    placeholder="Enter Rate of Product"
                                    value={SelectedProduct.product_rate}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="common-input-section">
                                  <label htmlFor="">Product Preview</label>
                                  <div className="Belt_img">
                                    {SelectedProduct.product_images &&
                                    SelectedProduct.product_images.length >
                                      0 ? (
                                      SelectedProduct.product_images.map(
                                        (itemlist, index) => {
                                          return (
                                            <>
                                              {itemlist.product_image_path ===
                                                null ||
                                              itemlist.product_image_path ===
                                                "" ? (
                                                <img src={Belt_img} alt="" />
                                              ) : (
                                                <img
                                                  src={
                                                    itemlist.product_image_path
                                                  }
                                                  alt=""
                                                />
                                              )}
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Button_Bottom">
                          <button
                            className="cx-btn-Light"
                            onClick={() => {
                              setProductCat("All");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="cx-btn-dark"
                            onClick={() => {
                              EditProductPost(
                                SelectedProduct.pharmacy_shop_product_id
                              );
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </>
                    ) : null}

                    {productCat === "Add" ? (
                      <>
                        <div className="Form_main mt-4">
                          <div className="Heading">Add New Product</div>
                          <div className="Scroll_With_Bottom_Btn">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Product</label>
                                  {/* <Select
                                      options={FacilitiesType}
                                      // onChange={handleChange}
                                      //   onChange={onTopicChange}
                                      autoFocus={true}
                                      // placeholder={"Select Languages..."}
                                      placeholder={
                                        <div className="select-placeholder-text">
                                          Select Category...
                                        </div>
                                      }
                                    /> */}
                                  <Select
                                    options={ProductType}
                                    disabled={
                                      productCat === "Add" ? false : true
                                    }
                                    onChange={onTopicChange}
                                    autoFocus={true}
                                    // placeholder={"Select Languages..."}
                                    placeholder={
                                      <div className="select-placeholder-text">
                                        Select Product...
                                      </div>
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Category</label>

                                  <input
                                    type="text"
                                    placeholder="Product Brand"
                                    value={SelectedProduct.product_type}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Brand</label>
                                  <input
                                    type="text"
                                    placeholder="Product Brand"
                                    value={SelectedProduct.product_brand}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Code</label>
                                  <input
                                    type="text"
                                    placeholder="Product Code"
                                    value={SelectedProduct.product_code}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Quantity</label>
                                  <input
                                    type="text"
                                    placeholder="Enter Quantity"
                                    value={
                                      SelectedProduct.pharmacy_shop_product_quantity
                                    }
                                    onChange={(e) => {
                                      if (validNumber.test(e.target.value)) {
                                        setSelectedProduct({
                                          ...SelectedProduct,
                                          pharmacy_shop_product_quantity:
                                            e.target.value,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-6 mb-4">
                                <div className="common-input-section">
                                  <label htmlFor="">Rate</label>
                                  <input
                                    type="text"
                                    placeholder="Enter Rate of Product"
                                    value={SelectedProduct.product_rate}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="common-input-section">
                                  <label htmlFor="">Product Preview</label>
                                  <div className="Belt_img">
                                    {SelectedProduct.product_images &&
                                    SelectedProduct.product_images.length >
                                      0 ? (
                                      SelectedProduct.product_images.map(
                                        (itemlist, index) => {
                                          return (
                                            <>
                                              {itemlist.product_image_path ===
                                                null ||
                                              itemlist.product_image_path ===
                                                "" ? (
                                                <img src={Belt_img} alt="" />
                                              ) : (
                                                <img
                                                  src={
                                                    itemlist.product_image_path
                                                  }
                                                  alt=""
                                                />
                                              )}
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {Inventory.length ? (
                                    ""
                                  ) : (
                                    <p
                                      style={{
                                        fontSize: "20px",
                                        marginTop: "200px",
                                      }}
                                      className=" text-center justify-content-center align-items-center"
                                    >
                                      Oops! It doesn't look like there are any
                                      Inventory?
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Button_Bottom">
                          <button
                            className="cx-btn-Light"
                            onClick={() => {
                              setProductCat("All");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="cx-btn-dark"
                            onClick={() => {
                              addProductPost();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </>
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div className="search-filter-wrapper">
                      <div className="search-filter-left">
                        {/* <div className="row">
                            <div className="col-lg-5 mb-2">
                              <input
                                type="text"
                                className="form-control serach-input1 "
                                placeholder="Search by Name, ID..."
                              />
                            </div>
                            <div className="col-lg-1 mb-2">
                              <button className="fltr-btn">
                                <img src={ic_filter} alt="" />
                              </button>
                            </div>
                          </div> */}
                      </div>
                    </div>
                    <div className="Main_Page_Scroll mt-4">
                      <div className="row">
                        {InventoryCategory && InventoryCategory.length > 0 ? (
                          InventoryCategory.map((itemlist, index) => {
                            return (
                              <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="INventorySingle">
                                  <div className="InventoryImage mb-2">
                                    <img src={InventoryProduc2} alt="" />
                                  </div>
                                  <div className="ProductDetails">
                                    <div className="productName">
                                      <p className="name">
                                        {itemlist.category}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="prising mb-0">
                                    <div className="QuantityDetails">
                                      <p className="Quantity LightColor">
                                        Products
                                      </p>
                                      <p className="QuantityCount">
                                        {itemlist.total_products}
                                      </p>
                                    </div>
                                    <div className="line"></div>
                                    <div className="PriceDetails">
                                      <p className="Price LightColor">Brands</p>
                                      <p className="PriceCount">
                                        {itemlist.total_brands}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      {Inventory.length ? (
                        ""
                      ) : (
                        <p
                          style={{
                            fontSize: "20px",
                            marginTop: "200px",
                          }}
                          className=" text-center justify-content-center align-items-center"
                        >
                          Oops! It doesn't look like there are any Inventory?
                        </p>
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </InfiniteScroll>
      </>
    </>
  );
};

export default Inventory;
