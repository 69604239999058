import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import searchIcon from "../assets/images/headerIcons/SearchIcon.svg";
import roundCircle from "../assets/images/headerIcons/headerCircle.svg";
import dropdown_icon from "../assets/images/headerIcons/dropdown_icon.svg";
import moon from "../assets/images/headerIcons/moon.svg";
import bell from "../assets/images/headerIcons/bell.svg";
import dd_fix_icon from "../assets/images/headerIcons/dd_fix_icon.svg";
import profile_img from "../assets/images/main_profile_img.svg";
import status_available from "../assets/images/headerIcons/status_available.svg";
import status_away from "../assets/images/headerIcons/status_away.svg";
import status_busy from "../assets/images/headerIcons/status_busy.svg";
import status_offline from "../assets/images/headerIcons/status_offline.svg";
import address_icon from "../assets/images/headerIcons/address_icon.svg";
import address_icon2 from "../assets/images/headerIcons/address_icon2.svg";
import profile_setting_icon from "../assets/images/headerIcons/profile_setting_icon.svg";
import logout_icon from "../assets/images/headerIcons/logout_icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { motion } from "framer-motion";
import { cubicBezier } from "framer-motion";
import { AppContext } from "../context/AppContext";
import ApiConfig from "../api/ApiConfig";
import {
  PutCallWithErrorResponse,
  simpleGetCall,
  simplePutAuthCall,
} from "../api/ApiServices";
import { notifySuccess } from "./notify";
import useImageLoader from "../customeHooks/useImageLoader";

const Header = () => {
  const navigate = useNavigate();
  const [statusArr, setStatusArr, updateImg] = useState([
    localStorage.getItem("json_agg"),
  ]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [status, setStatus] = useState({
    imageName: status_offline,
    status_title: "Status",
  });
  const [updateStatus, setUpdateStatus] = useState({});

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(!isHovering);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // setIsHovering(!isHovering)
          setClicked(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const aninations = {
    initial: { opacity: 0, y: 0 },

    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const {
    sidebar,
    setSidebar,
    userData,
    setUserData,
    profileData,
    setProfileData,
    notificationCount,
    noticationList,
    setnoticationList,
    socket
  } = useContext(AppContext);

  const { pharmacy_id } = userData;
  const user_status = localStorage.user_status ? JSON.parse(localStorage.user_status) : []
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;

  const logoutAction = (e) => {
    e.preventDefault();
    localStorage.clear();
    setUserData({
      ...userData,
      isLoggedIn: false,
    });
    socket && socket.disconnect()
  };

  useEffect(() => {
    const updatedStatusObj =
      user_status &&
      user_status.find((ele) => ele.status_id == profileData?.status_id);
    setUpdateStatus(updatedStatusObj);
  }, [profileData]);

  useEffect(() => {
    getNoticationDetails();

    return () => {
      setProfileData({})
    }
  }, []);

  function getNoticationDetails() {
    simpleGetCall(ApiConfig.Notification_LIST + userData.user_id)
      .then((res) => {
        setnoticationList(res?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }

  // UPDATE_USER_STATUS

  const updateUserStatus = (status_id) => {
    const payLoad = {
      pharmacy_id: pharmacy_id,
      status_id: status_id,
    };
    simplePutAuthCall(ApiConfig.UPDATE_USER_STATUS, JSON.stringify(payLoad))
      .then((res) => {
        const { success } = res;
        if (success) {
          notifySuccess("Status updated successfully !");
          const updatedStatusObj =
            user_status &&
            user_status.find((ele) => ele.status_id == status_id);
          setUpdateStatus(updatedStatusObj);
        }
      })
      .catch((err) => {
        console.log("catch error of user Status API,", err);
      });
  };
  const { loading, imageSrc } = useImageLoader(profileData?.profile_image_path, profile_img);

  return (
    <>
      <main className="mainHeader">
        <header className="top-header">
          {/* <div className="d-flex flex-column">
            <div className="breadCrumbsTop">
              <label className={sidebar ? "leftSideBread" : "leftHeadBread"}>
                {(currentRoute === "/Doctors" && "Doctors") ||
                  (currentRoute === "/DoctorDetails" && (
                    <>
                      {" "}
                      <Link to="/Doctors">Doctor /</Link> Doctor Details{" "}
                    </>
                  ))}

                {(currentRoute === "/RevenueReports" && "RevenueReports") ||
                  (currentRoute === "/RevenueReports" && (
                    <>
                      {" "}
                      <Link to="/RevenueReports">Doctor /</Link> Doctor Details{" "}
                    </>
                  ))}
              </label>
            </div>
            <div className="headingBreads">
              <label className={sidebar ? "leftSideCloseBar" : "leftHeadTxt"}>
                {(currentRoute === "/Dashboard" && "Dashboard") ||
                  (currentRoute === "/Doctors" && "Doctors") ||
                  (currentRoute === "/DoctorDetails" && "Doctor Details")}
              </label>
            </div>
          </div> */}
          <div className="left-breadcurm">
            {/* <label className="page-name-heading" htmlFor="">Dashboard<Link to="#" ></Link></label> */}
            {(currentRoute === "/Dashboard" && (
              <label className="page-name-heading" htmlFor="">
                Dashboard<Link to="#"></Link>
              </label>
            )) ||
              (currentRoute === "/CustomerDetails" && (
                <label className="page-name-heading" htmlFor="">
                  Customer Details<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/AtClinic" && (
                <label className="page-name-heading" htmlFor="">
                  Appointments/ <Link to="#">At Clinic</Link>
                </label>
              )) ||
              (currentRoute === "/Video" && (
                <label className="page-name-heading" htmlFor="">
                  Appointments/ <Link to="#">Video</Link>
                </label>
              )) ||
              (currentRoute === "/SalesReports" && (
                <label className="page-name-heading" htmlFor="">
                  Sales Report<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/InventoryReports" && (
                <label className="page-name-heading" htmlFor="">
                  Inventory Reports<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/Order" && (
                <label className="page-name-heading" htmlFor="">
                  Order's<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/TransactionDetails" && (
                <label className="page-name-heading" htmlFor="">
                  Transaction Details<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/Subscription" && (
                <label className="page-name-heading" htmlFor="">
                  Subscription<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/MyProfile" && (
                <label className="page-name-heading" htmlFor="">
                  My Profile<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/Inventory" && (
                <label className="page-name-heading" htmlFor="">
                  Inventory<Link to="#"></Link>
                </label>
              )) ||
              (currentRoute === "/AddClinic" && (
                <label className="page-name-heading" htmlFor="">
                  <Link to="/MyProfile">My Profile</Link> / Add Clinic
                </label>
              )) ||
              (currentRoute === "/AddTeam" && (
                <label className="page-name-heading" htmlFor="">
                  <Link to="/MyProfile">My Profile</Link> / Add Team
                </label>
              ))}
          </div>
          <div
            className="searchBlog"
            ref={wrapperRef}
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={handleMouseOut}
          >
            <div className={isHovering ? "innerSearch" : "smallInnerSearch"}>
              <input
                type="text"
                className="form-control innerForm"
                placeholder="search"
              />
              <img src={searchIcon} className="searchInside" alt="" />
              <div
                className="notiBox"
                onClick={() => navigate("/Notification")}
              >
                <img src={bell} className="insideIcons" alt="" />
                <div className="bagde">
                  <span>
                    {noticationList?.length ? noticationList?.length : 0}
                  </span>
                </div>
              </div>
              <img src={moon} className="insideIcons" alt="" />

             {!loading && <img
                src={imageSrc}
                onClick={() => setClicked(!clicked)}
                className="rounded-circle circleImg"
                alt=""
              // onMouseOver={() => setIsHovering(true)}
              // onMouseOut={handleMouseOut}
              />}

              {clicked && (
                <motion.div
                  variants={aninations}
                  initial="initial"
                  animate="animate"
                  // exit="exit"
                  transition={{ duration: 0.4, delay: 0 }}
                  className="profile_dropdown"
                >
                  <p className="pd_title">
                    {" "}
                    <span>Animal App -</span> Premium Plus
                  </p>
                  <div className="pd_profile_description_wrapper">
                    <div className="pdf_img">
                      <img
                        src={
                          profileData?.profile_image_path
                            ? profileData?.profile_image_path
                            : profile_img
                        }
                        alt=""
                      />
                      <span>
                        {/* <img
                          src={
                            status.imageName ? status.imageName : status_offline
                          }
                          alt=""
                        /> */}
                      </span>
                    </div>
                    <div className="pdf_name_status">
                      <p className="name">{profileData?.pharmacy_shop_name}</p>
                      <p className="post">
                        {profileData?.type_of_pharmacy_shop}
                      </p>
                      <div className="pd_status_selection">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <div>
                              <img
                                src={
                                  updateStatus?.status_id === 6
                                    ? status_available
                                    : updateStatus?.status_id === 7
                                      ? status_away
                                      : updateStatus?.status_id === 8
                                        ? status_busy
                                        : updateStatus?.status_id === 9
                                          ? status_offline
                                          : dd_fix_icon
                                }
                                alt=""
                              />
                              {updateStatus?.status?.charAt(0).toUpperCase() +
                                updateStatus?.status?.slice(1)}
                              {/* {status.status_title
                                ? status.status_title
                                : "Status"}{" "} */}
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {/* {
                              userData && userData
                            } */}
                            <Dropdown.Item
                              onClick={() => {
                                updateUserStatus(user_status[0]?.status_id);
                                // setStatus({
                                //   imageName: status_available,
                                //   status_title: "Available",
                                // });
                              }}
                            >
                              <div>
                                <img src={status_available} alt="" />
                                Available
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                updateUserStatus(user_status[1]?.status_id);
                                // setStatus({
                                //   imageName: status_away,
                                //   status_title: "Away",
                                // });
                              }}
                            >
                              <div>
                                <img src={status_away} alt="" />
                                Away
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                updateUserStatus(user_status[2]?.status_id);
                                // setStatus({
                                //   imageName: status_busy,
                                //   status_title: "Busy",
                                // });
                              }}
                            >
                              <div>
                                <img src={status_busy} alt="" />
                                Busy
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                updateUserStatus(user_status[3]?.status_id);
                                // setStatus({
                                //   imageName: status_offline,
                                //   status_title: "Offline",
                                // });
                              }}
                            >
                              <div>
                                <img src={status_offline} alt="" />
                                Offline
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="pd_status_selection mt-2 border_bottom">
                    <div className="adressBox">
                      <img src={address_icon} alt="" />
                      {userData?.address}
                    </div>
                    {/* <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div>
                            <img
                              src={address_icon2}
                              alt=""
                              className="add_icon"
                            />
                            Vishrantwadi, Kalas Road, Pune.
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div>
                            <img
                              src={address_icon2}
                              alt=""
                              className="add_icon"
                            />
                            Vishrantwadi, Kalas Road, Pune.
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div>
                            <img
                              src={address_icon2}
                              alt=""
                              className="add_icon"
                            />
                            Vishrantwadi, Kalas Road, Pune.
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                  <div className="pd_status_selection mt-2 border_bottom2">
                    <div
                      className="profileNavig" //</Dropdown.Toggle>onClick={() => navigate("/MyProfile")}
                    >
                      <img src={profile_setting_icon} alt="" />
                      <Link to={`/MyProfile/${userData?.pharmacy_id}`}>
                        My Profile settings
                      </Link>{" "}
                    </div>
                    {/* <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                      </Dropdown.Toggle>
                    </Dropdown> */}
                  </div>
                  <div className="logout_btn_wrapper">
                    <button onClick={logoutAction}>
                      {" "}
                      <img src={logout_icon} alt="" /> Logout
                    </button>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
        </header>
      </main>
    </>
  );
};

export default Header;
