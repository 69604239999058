import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import earn_icon from "../../assets/images/icons/earn_icon.svg";
import patient_icon from "../../assets/images/pet-shop-img/Product_icon.svg";
import customer from "../../assets/images/pet-shop-img/Customer_icon.svg";
import order from "../../assets/images/pet-shop-img/Order_icon.svg";
import subs_icon from "../../assets/images/icons/subs_icon.svg";
import clock_icon from "../../assets/images/pet-shop-img/box1.svg";
import clock_icon1 from "../../assets/images/pet-shop-img/box2.svg";
import active_icon from "../../assets/images/icons/active_icon.svg";
import appoint_img1 from "../../assets/images/pet-shop-img/Belt_img.svg";
import appoint_img2 from "../../assets/images/pet-shop-img/Press_icon.svg";
import appoint_img3 from "../../assets/images/pet-shop-img/FoodTrey_icon.svg";
import appoint_img4 from "../../assets/images/appoint_img4.svg";
import tc_patient_img1 from "../../assets/images/tc_patient_img1.svg";
import tc_patient_img2 from "../../assets/images/tc_patient_img2.svg";
import tc_patient_img3 from "../../assets/images/tc_patient_img3.svg";
import tc_patient_img4 from "../../assets/images/tc_patient_img4.svg";
import tc_patient_img5 from "../../assets/images/tc_patient_img5.svg";
import tc_patient_img6 from "../../assets/images/tc_patient_img6.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/pet-shop-img/pendding.svg";
import { motion } from "framer-motion";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { Nav, NavItem, Tab } from "react-bootstrap";
import { DonutChart } from "../commonComponents/commonComp";
import ApiConfig from "../../api/ApiConfig";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import { useEffect } from "react";
import Loader from "../../sharedComponent/Loader";
export const getDate = (inputDate) => {
  let date = new Date(inputDate);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  //dd-mm-yyyy
  //YYYY-MM-DD
  //MM-DD-YYYY
  //dd/mm/yyyy

  var formattedDate = `${day < 10 ? 0 : ""}${day}-${month < 10 ? 0 : ""
    }${month}-${year}`;

  return formattedDate;
};

const Dashboard = () => {
  const [revenueBar, setRevenueBar] = useState({
    options: {
      chart: {
        type: "line",
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ],
      },
      // yaxis: {
      //   min: 0,
      //   max: 800,
      // },
      colors: ["#6AD2FF", "#4318FF"],
    },
    series: [
      {
        // name: "Old Patient",
        name: "",
        data: [300, 340, 280, 460, 180, 520, 510, 1, 2, 3, 4, 5],
      },
    ],
  });
  // console.log("revenueBar",revenueBar);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const { setLoading, userData, loading } = useContext(AppContext);
  const [TotalData, setTotalData] = useState("");
  const [graphiceData, setgraphiceData] = useState([]);
  const [topCustomerData, setopCustomerData] = useState([]);
  const [topOrderData, setopOrderData] = useState([]);
  console.log("graphiceData", graphiceData);
  const [TopData, setTopData] = useState([]);

  useEffect(() => {
    setLoading(true)
    getDetails();
    getDetailsCustomer();
    getDetailsOrder();
  }, []);

  const getDetails = () => {
    simpleGetCallWithToken(ApiConfig.DASHBORD_LIST + userData.pharmacy_shop_id)
      .then((res) => {
        // setClintDetails(data?.data);

        if (res.success) {
          setLoading(false);
          setTotalData(res?.totaldata);
          setgraphiceData(res.data);
          setTopData(res?.topProducts);
        } else {

        }

      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getDetailsCustomer = () => {
    simpleGetCallWithToken(
      ApiConfig.CUSTOMER_LIST +
      userData.pharmacy_shop_id

    )
      .then((data) => {
        // setClintDetails(data?.data);
        setopCustomerData(data.data);

        if (data.success) {
          setLoading(false);

        } else {

        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getDetailsOrder = () => {
    simpleGetCallWithToken(ApiConfig.ORDER_DETALIS + userData.pharmacy_shop_id)
      .then((data) => {
        setopOrderData(data?.data);

        if (data.success) {
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      id="cx-main"
    >
      {loading === true ? (
        <Loader />
      ) : (
        <div className="dashboard_wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="dashboard_statistics_wrapper">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="stat_item">
                      <img src={earn_icon} />
                      <div className="stat_text stat_1">
                        <label>Earnings</label>
                        <p>{TotalData?.earning}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="stat_item">
                      <img src={patient_icon} />
                      <div className="stat_text stat_2">
                        <label>Products</label>
                        <p>{TotalData?.products}</p>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="stat_item">
                      <img src={customer} />
                      <div className="stat_text stat_4">
                        <label>Order</label>
                        <p>{TotalData?.orders}</p>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="stat_item">
                      <img src={order} />
                      <div className="stat_text stat_5">
                        <label>Customers</label>
                        <p>{TotalData?.customers}</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mb-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="line_chart_wrapper">
                    <div className="lc_top">
                      <div className="lct_header_wrapper">
                        <p>Sales Overview</p>
                        <div className="lc_bottom">
                          <div className="selection"></div>
                        </div>
                      </div>
                    </div>

                    <Chart
                      options={revenueBar.options}
                      series={graphiceData}
                      type="line"
                      width="90%"
                      height="260"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="dashboard_appointments_wrapper">
                <div className="lc_top">
                  <div className="lct_header_wrapper">
                    <p>Top Products </p>
                    <div className="appoint_link">
                      <Link to="/Inventory">View All</Link>
                    </div>
                  </div>
                </div>
                <div className="daw_tabs">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="dash_appoint_list">
                        {TopData &&
                          TopData.length > 0 ? (
                          TopData.map((item, index) => {
                            return (
                              <div className="dash_list_item" key={"TopData" + index}>
                                <div className="dli_left">
                                  <img src={item.product_image_path} alt="" />
                                  <div className="dli_text">
                                    <p className="dli_appointment_title">
                                      {item.product}
                                    </p>
                                    <p className="patient_name">                        {item.product_type}
                                    </p>
                                    <div className="time_wrapper">
                                      <img src={clock_icon} alt="" />
                                      <p className="time">{item.in_stock} pcs Left</p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="dli_right">
                                <Link to="#">View Details</Link>
                              </div> */}
                              </div>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 mb-4">
              <div className="top_customer_table_wrapper">
                <div className="lc_top">
                  <div className="lct_header_wrapper" style={{ border: "none" }}>
                    <p>Recent Customer</p>
                    <div className="appoint_link">
                      <Link to="/CustomerDetails">View All</Link>
                    </div>
                  </div>
                </div>
                <div className="dasboard-cust-tb-wrapper heightAdjuster">
                  <table className="top_customer_table">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Date</th>
                        <th>Owner</th>
                        <th>Pet</th>
                        <th>Pet Type</th>
                        {/* <th>Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {topCustomerData && topCustomerData.length > 0 ? (
                        topCustomerData.map((item, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item.order_date}</td>
                              <td>{item.customer_name}</td>
                              <td>
                                <div className="tct_patient_wrapper">
                                  <img
                                    src={

                                      item.animal_photograph_path
                                    }
                                    alt=""
                                  />
                                  <p className="tct_patient_name">
                                    {item.animal_name}
                                  </p>
                                </div>
                              </td>
                              <td>{item.type_of_animal}</td>
                              {/* <td>
                        <img src={ic_rejected} className="status-ic" alt="" />
                        In Progress
                      </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                  {topCustomerData?.length ? (
                    ""
                  ) : (
                    <p
                      style={{
                        fontSize: "20px",
                        marginTop: "170px",
                      }}
                      className=" text-center justify-content-center align-items-center"
                    >
                      Oops! It doesn't look like there are any Recent Customer?
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="top_customer_table_wrapper2">
                <div className="lc_top">
                  <div className="lct_header_wrapper" style={{ border: "none" }}>
                    <p>Orders</p>
                    <div className="appoint_link">
                      <Link to="/Order">View All</Link>
                    </div>
                  </div>
                </div>
                <div className="dasboard-cust-tb-wrapper heightAdjuster">
                  <table className="top_customer_table2 ">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Order Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topOrderData && topOrderData.length > 0 ? (
                        topOrderData.map((item, index) => {
                          return (
                            <tr>
                              <td>#{item.order_id}</td>
                              <td>{getDate(item.order_date)}</td>
                              <td>
                                {/* <img src={ic_rejected} className="status-ic" alt="" /> */}

                                {item.status}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                  {topOrderData?.length ? (
                    ""
                  ) : (
                    <p
                      style={{
                        fontSize: "17px",
                        marginTop: "140px",
                      }}
                      className=" text-center justify-content-center align-items-center"
                    >
                      Oops! It doesn't look like there are any Orders?
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </motion.div>
  );
};

export default Dashboard;
