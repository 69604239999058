import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo.svg";
import teleCall from "../../assets/images/icons/telephone.svg";
import insiderImg from "../../assets/images/icons/landigIcon.svg";
import telephone from "../../assets/images/icons/telephone.svg";
import otp_icon from "../../assets/images/icons/otp_icon.svg";
import tele_icon from "../../assets/images/icons/tele_icon.svg";
import forgot_pass from "../../assets/images/forgot_pass.svg";
import { Link, useNavigate } from "react-router-dom";
import { DarkButton, HandleRoute } from "../commonComponents/commonComp";
import Form from "react-bootstrap/Form";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import { simplePostAuthCallNoTokan } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import ic_left_green_arrow from "../../assets/images/ic_left_green_arrow.svg";

const ForgotPass = () => {
  const { validNumber } = useContext(AppContext);
  const navigate = useNavigate("");
  const [validated, setValidated] = useState(false);
  const [forgetPassData, setForgetPassData] = useState({
    phone_number: null,
    otp: null,
    hash: "",
  });
  const changeEveVaue = (e) => {
    setForgetPassData({
      ...forgetPassData,
      [e.target.name]: e.target.value,
    });
  };
  const handleRoute = (route) => {
    navigate(route);
  };

  const [seconds, setSeconds] = useState(60);
  const [firstClick, setFirstClick] = useState(false);

  const startTimer = () => {
    // setSeconds(60);
    if (seconds > 0) {
      let interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  const handleOtp = () => {
    if (forgetPassData?.phone_number.length !== 10) {
      notifyError("Please enter valid mobile number");
      return;
    }
    let payload = {
      phone_number: forgetPassData?.phone_number,
    };
    if (forgetPassData.phone_number !== null) {
      simplePostAuthCallNoTokan(ApiConfig.GET_OTP, JSON.stringify(payload))
        .then((res) => {
          if (res.success) {
            startTimer();
            notifySuccess("OTP Sent Successfully");
            setForgetPassData({
              ...forgetPassData,
              hash: res.hash,
            });
          }
        })
        .catch((err) => {
          notifyError(err);
        });
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      submitPass(event);
    }
    setValidated(true);
  };

  const submitPass = (event) => {
    event.preventDefault();
    simplePostAuthCallNoTokan(
      ApiConfig.FORGOT_PASSWORD,
      JSON.stringify(forgetPassData)
    )
      .then((res) => {
        if (res.success === true) {
          notifySuccess(res.message);
          localStorage.setItem("pharmacy_id", res.pharmacy_id);
          navigate("/ResetPass");
        }
      })
      .catch((err) => {
        notifyError(err);
      });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="authMain">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <img src={logo} alt="" className="login_img" />
              <div style={{ display: "flex", marginTop: "35px" }}>
                <div>
                  <Link to={"/"}>
                    <img src={ic_left_green_arrow} alt="" />
                  </Link>
                </div>
                <div className="signinForm">
                  <h1 className="signInTxt">Forgot your password ?</h1>
                  <p className="signInDesc">
                    You can reset your password Here
                    {/* If you forgot your password, Don't Worry ! <br /> You can
                  request
                  <Link className="link_common" to="/SignUp">
                    {" "}
                    New here !
                  </Link> */}
                  </p>
                  <Form.Group className="inputBox">
                    {seconds == 60 && (
                      <button
                        type="button"
                        className="otpbtn"
                        onClick={handleOtp}
                      >
                        Request OTP
                      </button>
                    )}
                    {seconds !== 60 && (
                      <button
                        type="button"
                        className="resendotpbtn"
                        onClick={() => {
                          if (seconds == 0) {
                            handleOtp();
                          }
                        }}
                      >
                        Re-send OTP ({seconds})
                      </button>
                    )}

                    <Form.Label htmlFor="" className="emailLabel">
                      Phone Number
                    </Form.Label>
                    <img src={telephone} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="phone_number"
                      value={forgetPassData.phone_number}
                      onChange={(e) => {
                        if (validNumber.test(e.target.value)) {
                          changeEveVaue(e);
                        }
                      }}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter your phone number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter phone number
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="inputBox">
                    <Form.Label htmlFor="" className="emailLabel">
                      OTP
                    </Form.Label>
                    <img src={otp_icon} className="mailImg" alt="" />
                    <Form.Control
                      required
                      name="otp"
                      value={forgetPassData.otp}
                      onChange={(e) => {
                        if (validNumber.test(e.target.value)) {
                          changeEveVaue(e);
                        }
                      }}
                      type="text"
                      className="form-control formInput"
                      placeholder="Enter OTP here..."
                    />
                    <Form.Control.Feedback type="invalid">
                      Please Provide OTP Received
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="login btn-width-100">
                    <DarkButton
                      // onClick={() => handleRoute("/ResetPass")}
                      type="submit"
                      classbtn={
                        "cx-btn-dark btn-width-100 btn-height font-17 mb-5"
                      }
                      btn={"Reset Password"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="landingImg2">
                <p className="tele">
                  {" "}
                  <img src={tele_icon} alt="" /> +91 9509932234
                </p>
                <img src={forgot_pass} alt="" className="auth_main_img" />
                <div className="text_wrapper">
                  <h2 className="tw_title">Forgot Password ?</h2>
                  <p className="tw-sub_title">No Worries ! We got your back.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ForgotPass;
