import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import wallet_icon from "../../assets/images/icons/wallet_icon.svg";
import export_icon from "../../assets/images/icons/export_icon.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import cat from "../../assets/images/cat.png";
import ic_filter from "../../assets/images/ic_filter.svg";
import { motion } from "framer-motion";
import { DarkButton, DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import {
  simpleGetCall,
  simpleGetCallWithToken,
  simplePostAuthCall,
  simplePostCall,
} from "../../api/ApiServices";
import { AppContext } from "../../context/AppContext";
import Loader from "../../sharedComponent/Loader";
import Modal from "react-bootstrap/Modal";
import { notifyError, notifySuccess } from "../../sharedComponent/notify";
import Chart from "react-apexcharts";
import moment from "moment";
import { formatCurrency } from "../../common/common";

const TransactionDetails = () => {
  const [viewChange, setviewChange] = useState(true);
  const [withdraw, setWithdraw] = useState(false);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const [ProductNameFilter, setProductNameFilter] = useState("");

  const { setLoading, userData, loading } = useContext(AppContext);
  const { user_id } = userData;
  const todaysDate = new Date();
  const [Transactionsdetails, setTransactionsdetails] = useState([]);
  const [Upcomingdetails, setUpcomingdetails] = useState([]);
  const [TotalEarnings, setTotalEarnings] = useState("");
  const pha_id = localStorage.getItem("user_id");
  const [dropdownKey, setDropdownKey] = useState([]);
  const [withdrawDaat, setWithdrawDaat] = useState({
    user_id: pha_id,
    request_reason: "",
    request_amount: null,
    bank_id: null,
  });

  const getBankListDrop = () => {
    setWithdraw(!withdraw);
    simpleGetCall(
      ApiConfig.GET_PAYMENT_DROPDOWN + `?user_id=${pha_id}&type=${"pharma"}`
    )
      .then((res) => {
        const extractedData = res?.data?.map((item) => ({
          bankName: item.pharmacy_bank,
          bankId: item.pharmacy_bank_id,
        }));
        setDropdownKey(extractedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const postWithdrawAmnt = () => {
  //   if (withdrawDaat.request_amount <= TotalEarnings) {
  //     if (withdrawDaat.request_amount !== null) {
  //       simplePostCall(ApiConfig.POST_WITHDRAW_REQUEST, JSON.stringify(withdrawDaat))
  //         .then((res) => {
  //           notifySuccess(res?.message)
  //           setWithdraw(false)
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         })
  //     } else {
  //       notifyError("All fields are required")
  //     }
  //   } else {
  //     notifyError("Selected amount is greater than your wallet amount")
  //   }
  // }

  const postWithdrawAmnt = () => {
    if (withdrawDaat.request_amount <= TotalEarnings) {
      if (withdrawDaat.request_amount !== null) {
        simplePostCall(
          ApiConfig.POST_WITHDRAW_REQUEST,
          JSON.stringify(withdrawDaat)
        )
          .then((res) => {
            notifySuccess(res?.message);
            setWithdraw(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        notifyError("All fields are required");
      }
    } else {
      notifyError("Please select a valid amount");
    }
  };

  useEffect(() => {
    getDetailsUpcoming();
    if (ProductNameFilter === "") {
      setLoading(true);
      getDetails();
    } else {
      getDetails();
    }
  }, [ProductNameFilter]);

  useEffect(() => {
    getDetailsUpcoming();
    fetchPaymentAnalytics(todaysDate, todaysDate);
  }, []);

  const getDetails = () => {
    simpleGetCallWithToken(
      ApiConfig.TRANSACTION_RECENT_LIST +
        userData.pharmacy_shop_id +
        "&filter=" +
        ProductNameFilter
    )
      .then((data) => {
        if (data.success) {
          setTransactionsdetails(data?.data);
          setTotalEarnings(data?.earning);
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const getDetailsUpcoming = () => {
    simpleGetCallWithToken(
      ApiConfig.TRANSACTION_UPCOMING_LIST + userData.pharmacy_shop_id
    )
      .then((data) => {
        if (data.success) {
          setUpcomingdetails(data?.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [paymentAnalytics, setpaymentAnalytics] = useState({
    withdrawPercentage: 0,
    receivedPercentyage: 0,
  });

  const tempArr = Object.keys(paymentAnalytics).map((key) =>
    Number(paymentAnalytics[key])
  );
  const arr = tempArr && tempArr.map((ele) => parseFloat(ele.toFixed(2)));

  const [donut, setDonut] = useState({
    options: {
      dataLabels: {
        enabled: false,
      },
      colors: ["#FFB200", "#01B529", "#5500DF"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      labels: ["Withdrawl", "Received"],
    },
    series: arr,
    style: {
      // colors: ["#FFB200", "#01B529", "#5500DF"],
    },
  });

  const fetchPaymentAnalytics = async (fromData, toData) => {
    const userId = user_id && user_id?.split("_")[1];
    const payLoad = {
      user_id: userId,
      from_date: moment(fromData).format("YYYY-MM-DD"),
      to_date: moment(toData).format("YYYY-MM-DD"),
    };
    try {
      const res = await simplePostAuthCall(
        `${ApiConfig.FETCH_PAYMENT_ANALYTICS}`,
        JSON.stringify(payLoad)
      );
      const { success, data } = res;
      if (success && data) {
        setpaymentAnalytics({
          withdrawPercentage: data?.WithdrawPercentage,
          receivedPercentyage: data?.ReceviedPercentage,
        });
      }
    } catch (error) {
      console.log("catch error,", error);
    }
  };

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.3 }}
      className="appointment-wrapper reports_wrapper transaction_details_wrapper"
      id="cx-main"
    >
      {loading === true ? (
        <Loader />
      ) : (
        <div className="transaction_details" id="TransactionDetails">
          <div className="search-filter-wrapper mb-4">
            <div className="search-filter-left">
              <div className="row">
                <div className="col-lg-5 mb-2">
                  <input
                    type="text"
                    className="form-control serach-input"
                    placeholder="Search by Name, Specialist, Ratings, Visits..."
                    value={ProductNameFilter}
                    onChange={(e) => {
                      setProductNameFilter(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="col-lg-1">
                <button className="fltr-btn">
                  <img src={ic_filter} alt="" />
                </button>

              </div> */}
              </div>
            </div>
          </div>
          <div className="wrapper-main">
            <div className="row">
              <div className="col-lg-8">
                <div className="table-wrapper table_wrapper_pagination py-4">
                  <div className="reportsTabs">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                      <Nav
                        variant="pills"
                        id="newTabMai"
                        className="tob_nav_pills d-flex"
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="1">Recent Transactions</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="2">Withdraw History</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Link to="#" className="export_button custom_border">
                        <img src={export_icon} alt="" />
                      </Link>
                      <Tab.Content>
                        <Tab.Pane eventKey="1">
                          <div className="dashboard_wrapper2">
                            <div className="table_wrapper_pagination dasboard-cust-tb-wrapper">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Product</th>
                                    <th>Category</th>
                                    <th>Sale</th>
                                    <th>In Stock</th>
                                    <th>Revenue</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Transactionsdetails &&
                                  Transactionsdetails.length > 0 ? (
                                    Transactionsdetails.map(
                                      (itemlist, index) => {
                                        return (
                                          <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                              <label
                                                htmlFor=""
                                                className="photo"
                                              >
                                                {itemlist.product}
                                              </label>
                                            </td>
                                            <td>{itemlist.product_type}</td>
                                            <td>{itemlist.sale}</td>
                                            <td>{itemlist.in_stock}</td>
                                            <td>₹{itemlist.revenue}</td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {Transactionsdetails.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "200px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Transactions?
                              </p>
                            )}
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="dashboard_wrapper">
                            <div className="table-wrapper table_wrapper_pagination p-0 dasboard-cust-tb-wrapper">
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Name</th>
                                    <th>Due Date</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Upcomingdetails &&
                                  Upcomingdetails.length > 0 ? (
                                    Upcomingdetails.map((itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <label htmlFor="" className="photo">
                                              {itemlist.customer_name}
                                            </label>
                                          </td>
                                          <td> {itemlist.due_date}</td>
                                          <td>{itemlist.description}</td>
                                          <td>₹{itemlist.amount}</td>
                                          <td style={{ cursor: "pointer" }}>
                                            {itemlist?.status}
                                            {/* <img
                                              src={wallet_icon}
                                              className="status-ic"
                                              alt=""
                                            />
                                            Pay Now */}
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {Upcomingdetails.length ? (
                              ""
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  marginTop: "200px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Transactions?
                              </p>
                            )}
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="patient_stat_wrapper">
                  <div className="lc_top">
                    <div className="lct_header_wrapper">
                      <p>My Earnings</p>
                    </div>
                    <div className="earning_amount">
                      <p>{formatCurrency(TotalEarnings)}</p>
                      <p>Bank- State Bank Of India</p>
                      {/* <p>*Early withdrawal charges may apply.</p> */}
                      <DarkButton
                        classbtn={
                          "cx-btn-dark me-auto  btn-width-100 btn-height font-17"
                        }
                        onClick={getBankListDrop}
                        btn={"Withdraw"}
                      />
                    </div>
                  </div>
                </div>

                <div className="donut_chart_wrapper">
                  <div className="dc_header">
                    <p>Payment Analytics</p>
                    <div className="selection">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        // value={"1"}
                        onChange={(e) => {
                          const { value } = e.target;
                          const firstDayOfMonth = new Date(
                            todaysDate.getFullYear(),
                            todaysDate.getMonth(),
                            1
                          );
                          const lastDayOfMonth = new Date(
                            todaysDate.getFullYear(),
                            todaysDate.getMonth() + 1,
                            0
                          );
                          if (value == "1") {
                            fetchPaymentAnalytics(todaysDate, todaysDate);
                          } else if (value == "2") {
                            fetchPaymentAnalytics(
                              firstDayOfMonth,
                              lastDayOfMonth
                            );
                          }
                        }}
                      >
                        <option value="1">Day</option>
                        <option value="2">Month</option>
                      </select>
                    </div>
                  </div>
                  {/* <DonutChart /> */}
                  <div>
                    {arr.some((element) => element !== 0) && (
                      <Chart
                        options={donut.options}
                        series={arr}
                        s
                        type="donut"
                        // width="280"
                        height="250"
                      />
                    )}
                  </div>
                  <div className="Chart_labels">
                    <div className="cl_item">
                      <p>Received</p>
                      <p>{paymentAnalytics.receivedPercentyage?.toFixed(2)}%</p>
                    </div>
                    {/* <div className="cl_item">
                              <p>Subscriptions</p>
                              <p>46%</p>
                            </div> */}
                    <div className="cl_item">
                      <p>Withdrawl</p>
                      <p>{paymentAnalytics.withdrawPercentage?.toFixed(2)}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal Of withdraw */}
      <div id="withdrawModal">
        {withdraw && (
          <Modal show={withdraw} centered onHide={() => setWithdraw(false)}>
            <Modal.Header closeButton className="tittleHead">
              <Modal.Title className="tittleModal">
                Withdraw Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="formBox">
                <div className="formBody">
                  <label htmlFor="" className="labelForm">
                    Bank Id
                  </label>
                  <select
                    onChange={(e) =>
                      setWithdrawDaat({
                        ...withdrawDaat,
                        bank_id: e.target.value,
                      })
                    }
                    className="form-select"
                  >
                    <option value="">Select Bank</option>
                    {dropdownKey && dropdownKey?.length > 0
                      ? dropdownKey.map((data, index) => {
                          return (
                            <option key={index} value={data?.bankId}>
                              {data?.bankName}
                            </option>
                          );
                        })
                      : null}
                  </select>
                </div>
                <div className="formBody">
                  <label htmlFor="" className="labelForm">
                    Request Reason
                  </label>
                  <textarea
                    rows="2"
                    className="form-control"
                    onChange={(e) =>
                      setWithdrawDaat({
                        ...withdrawDaat,
                        request_reason: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <div className="formBody">
                  <label htmlFor="" className="labelForm">
                    Request Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) =>
                      setWithdrawDaat({
                        ...withdrawDaat,
                        request_amount: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="buttonBox">
                  <button className="submitBtn" onClick={postWithdrawAmnt}>
                    Submit
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </motion.div>
  );
};

export default TransactionDetails;
