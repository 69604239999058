import React, { useContext, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { Tab, Tabs } from "react-bootstrap";
import earn_icon from "../../assets/images/icons/earn_icon.svg";
import patient_icon from "../../assets/images/icons/patient_icon.svg";
import total_appoint_icon from "../../assets/images/icons/total_appoint_icon.svg";
import complete_icon from "../../assets/images/icons/complete_icon.svg";
import cancelled_icon from "../../assets/images/icons/cancelled_icon.svg";
import medicine from "../../assets/images/pet-shop-img/Belt_img.svg";
import IvnReports1 from "../../assets/images/pet-shop-img/IvnReports (1).svg";
import IvnReports2 from "../../assets/images/pet-shop-img/IvnReports (2).svg";
import IvnReports3 from "../../assets/images/pet-shop-img/IvnReports (3).svg";
import IvnReports4 from "../../assets/images/pet-shop-img/IvnReports (4).svg";
import IvnReports5 from "../../assets/images/pet-shop-img/IvnReports (5).svg";
import revenue_icon from "../../assets/images/icons/revenue_icon.svg";
import calendar from "../../assets/images/icons/calendar.svg";
import export_icon from "../../assets/images/icons/export_icon.svg";
import ic_completed from "../../assets/images/ic_completed.svg";
import InProcessReport from "../../assets/images/pet-shop-img/InProcessReport.svg";
import ic_rejected from "../../assets/images/ic_rejected.svg";
import cat from "../../assets/images/cat.png";
import { motion } from "framer-motion";
import { DonutChart } from "../commonComponents/commonComp";
import { Link } from "react-router-dom";
import { simpleGetCall, simplePostAuthCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { AppContext } from "../../context/AppContext";
import Loader from "../../sharedComponent/Loader";

const InventoryReports = () => {
  const [viewChange, setviewChange] = useState(true);
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };
  const { setLoading, userData, loading } = useContext(AppContext);

  const [customersdetails, setcustomersdetails] = useState([]);
  const [customersdetailsMonthe, setcustomersdetailsMonthe] = useState([]);
  const [TotalData, setTotalData] = useState({});
  const [TotalDataMonthe, setTotalDataMonthe] = useState({});
  const [customersTopList, setcustomersTopList] = useState([]);
  const [customersTopListMonthe, setcustomersTopListMonthe] = useState([]);
  const [customersLowList, setcustomersLowList] = useState([]);
  const [customersLowListMonthe, setcustomersLowListMonthe] = useState([]);
  useEffect(() => {
    setLoading(true);
    getReportDetails();
    getReportDetailsMonthe();
  }, []);

  const getReportDetails = () => {
    setLoading(true);
    let newRequestBody = JSON.stringify({
      pharmacy_shop_id: userData.pharmacy_shop_id,
    });

    simplePostAuthCall(ApiConfig.INVENTORY_REPORT_DAILT, newRequestBody)
      .then((res) => {
        if (res.success) {
          setcustomersTopList(res.TopSellingProducts);
          setcustomersLowList(res.lowSellingProducts);
          setcustomersdetails(res.productReportList);
          setTotalData(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReportDetailsMonthe = () => {
    setLoading(true);
    let newRequestBody = JSON.stringify({
      pharmacy_shop_id: userData.pharmacy_shop_id,
    });

    simplePostAuthCall(ApiConfig.INVENTORY_MONTHE_REPORT, newRequestBody)
      .then((res) => {
        if (res.success) {
          setcustomersTopListMonthe(res.TopSellingProducts);
          setcustomersLowListMonthe(res.lowSellingProducts);
          setcustomersdetailsMonthe(res.productReportList);
          setTotalDataMonthe(res.data);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading === true ? (
        <Loader />
      ) : (
        <motion.div
          variants={aninations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.3 }}
          className="appointment-wrapper reports_wrapper"
          id="cx-main"
        >
          <div className="reportsTabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Nav
                variant="pills"
                id="newTabMai"
                className="tob_nav_pills d-flex"
              >
                <Nav.Item>
                  <Nav.Link eventKey="1">Daily</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2">Monthly</Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Link to="#" className="export_button2">
            <img src={calendar} alt="" />
          </Link> */}
              <Link to="#" className="export_button">
                <img src={export_icon} alt="" />
              </Link>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <div className="dashboard_wrapper" id="reports-wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dashboard_statistics_wrapper">
                              <div className="CustonTonSectionMain">
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports2} />
                                    <div className="stat_text stat_1">
                                      <label>Total Products</label>
                                      <p>{TotalData.total_products}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="CustonTonSection">
                                  {/* <div className="stat_item">
                                                                <img src={IvnReports3} />
                                                                <div className="stat_text stat_6">
                                                                    <label>Completed</label>
                                                                    <p>{TotalData.total_count}</p>

                                                                </div>
                                                            </div> */}
                                </div>
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports4} />
                                    <div className="stat_text stat_1">
                                      <label>High Stock</label>
                                      <p>{TotalData.high_count}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports5} />
                                    <div className="stat_text stat_YO">
                                      <label>Low- Stock</label>
                                      <p>{TotalData.low_count}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports1} />
                                    <div className="stat_text stat_5">
                                      <label>Out Of Stock</label>
                                      <p>{TotalData.out_of_stock}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-3">
                        <div className="dashboard_wrapper2">
                          <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Product</th>
                                  <th>Category</th>
                                  <th>Sale</th>
                                  <th>In Stock</th>
                                  <th>Revenue</th>
                                </tr>
                              </thead>
                              <tbody>
                                {customersdetails &&
                                customersdetails.length > 0 ? (
                                  customersdetails.map((itemlist, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist.product_image_path}
                                              alt=""
                                            />
                                          </label>
                                        </td>
                                        <td>{itemlist.product_type}</td>
                                        <td>{itemlist.total_items_sold}</td>

                                        <td>{itemlist.in_stock}</td>

                                        <td>₹{itemlist.total_amount_sold}</td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                          {customersdetails.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "20px",
                                // marginTop: "100px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Product?
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Top Selling Products</p>
                            </div>
                          </div>
                          {customersTopList && customersTopList.length > 0 ? (
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Product</th>
                                  <th>Sold</th>
                                  <th>In-Stock</th>
                                </tr>
                              </thead>
                              <tbody>
                                {customersTopList.map((itemlist, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <label htmlFor="" className="photo">
                                          <img
                                            src={itemlist.product_image_path}
                                            alt=""
                                          />
                                          {itemlist?.product}
                                        </label>
                                      </td>

                                      <td>{itemlist.total_items_sold}</td>

                                      <td>{itemlist.in_stock}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <p
                              style={{
                                fontSize: "17px",
                                padding: "20px 0",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Selling
                              Products?
                            </p>
                          )}
                        </div>
                        {/* {customersdetails.length ? (
                          ""
                        ) : (
                          <p
                            style={{
                              fontSize: "17px",
                              marginTop: "150px",
                            }}
                            className=" text-center justify-content-center align-items-center"
                          >
                            Oops! It doesn't look like there are any Selling
                            Products?
                          </p>
                        )} */}
                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Low Selling Products</p>
                            </div>
                          </div>
                          {customersLowList && customersLowList.length > 0 ? (
                            <table>
                              <thead>
                                <tr>
                                  <th>Sr. No</th>
                                  <th>Product</th>
                                  <th>Sold</th>
                                  <th>In-Stock</th>
                                </tr>
                              </thead>
                              <tbody>
                                {customersLowList.map((itemlist, index) => {
                                  return (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <label htmlFor="" className="photo">
                                          <img
                                            src={itemlist?.product_image_path}
                                            alt=""
                                          />
                                          {itemlist.product}
                                        </label>
                                      </td>
                                      <td>{itemlist.total_items_sold}</td>

                                      <td>{itemlist.in_stock}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          ) : (
                            <p
                              style={{
                                fontSize: "17px",
                                // marginTop: "50px",
                                padding: "20px 0",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any low
                              Selling Products?
                            </p>
                          )}
                          {/* {customersLowList.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "17px",
                                marginTop: "150px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Low
                              Selling Products?
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div className="dashboard_wrapper" id="reports-wrapper">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="dashboard_statistics_wrapper">
                              <div className="CustonTonSectionMain">
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports2} />
                                    <div className="stat_text stat_1">
                                      <label>Total Products</label>
                                      <p>{TotalDataMonthe.total_products}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="CustonTonSection">
                                  {/* <div className="stat_item">
                                                                <img src={IvnReports3} />
                                                                <div className="stat_text stat_6">
                                                                    <label>Completed</label>
                                                                    <p>{TotalData.total_count}</p>

                                                                </div>
                                                            </div> */}
                                </div>
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports4} />
                                    <div className="stat_text stat_1">
                                      <label>High Stock</label>
                                      <p>{TotalDataMonthe.high_count}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports5} />
                                    <div className="stat_text stat_YO">
                                      <label>Low- Stock</label>
                                      <p>{TotalDataMonthe.low_count}</p>
                                    </div>
                                  </div>
                                </div>
                                <div className="CustonTonSection">
                                  <div className="stat_item">
                                    <img src={IvnReports1} />
                                    <div className="stat_text stat_5">
                                      <label>Out Of Stock</label>
                                      <p>{TotalDataMonthe.out_of_stock}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-7 mb-3">
                        <div className="dashboard_wrapper2">
                          <div className="table-wrapper table_wrapper_pagination dasboard-cust-tb-wrapper">
                            {customersdetailsMonthe &&
                            customersdetailsMonthe.length > 0 ? (
                              <table>
                                <thead>
                                  <tr>
                                    <th>Sr. No</th>
                                    <th>Product</th>
                                    <th>Category</th>
                                    <th>Sale</th>
                                    <th>In Stock</th>
                                    <th>Revenue</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {customersdetailsMonthe.map(
                                    (itemlist, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <label htmlFor="" className="photo">
                                              <img
                                                src={
                                                  itemlist.product_image_path
                                                }
                                                alt=""
                                              />
                                            </label>
                                          </td>
                                          <td>{itemlist.product_type}</td>
                                          <td>{itemlist.total_items_sold}</td>

                                          <td>{itemlist.in_stock}</td>

                                          <td>₹{itemlist.total_amount_sold}</td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <p
                                style={{
                                  fontSize: "20px",
                                  padding : "50px 0"
                                  // marginTop: "100px",
                                }}
                                className=" text-center justify-content-center align-items-center"
                              >
                                Oops! It doesn't look like there are any
                                Product?
                              </p>
                            )}
                          </div>
                          {/* {customersdetailsMonthe.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "20px",
                                marginTop: "100px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Product?
                            </p>
                          )} */}
                          {/* <div className="pagination_wrapper">
                                                <p>Showing 1-10 of 100</p>
                                            </div> */}
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper mb-3">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Top Selling Products</p>
                            </div>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Product</th>
                                <th>Sold</th>
                                <th>In-Stock</th>
                              </tr>
                            </thead>
                            <tbody>
                              {customersTopListMonthe &&
                              customersTopListMonthe.length > 0 ? (
                                customersTopListMonthe.map(
                                  (itemlist, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist?.product_image_path}
                                              alt=""
                                            />
                                            {itemlist.product}
                                          </label>
                                        </td>
                                        <td>{itemlist.total_items_sold}</td>
                                        <td>{itemlist.in_stock}</td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                        </div>
                        {customersTopListMonthe.length ? (
                          ""
                        ) : (
                          <p
                            style={{
                              fontSize: "17px",
                              marginTop: "170px",
                            }}
                            className=" text-center justify-content-center align-items-center"
                          >
                            Oops! It doesn't look like there are any Selling
                            Products?
                          </p>
                        )}
                        <div className="table-wrapper ReportSiteTable table_wrapper_pagination dasboard-cust-tb-wrapper">
                          <div className="lc_top">
                            <div className="lct_header_wrapper">
                              <p>Low Selling Products</p>
                            </div>
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th>Sr. No</th>
                                <th>Product</th>
                                <th>Sold</th>
                                <th>In-Stock</th>
                              </tr>
                            </thead>
                            <tbody>
                              {customersLowListMonthe &&
                              customersLowListMonthe.length > 0 ? (
                                customersLowListMonthe.map(
                                  (itemlist, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <label htmlFor="" className="photo">
                                            <img
                                              src={itemlist?.product_image_path}
                                              alt=""
                                            />
                                            {itemlist.product}
                                          </label>
                                        </td>
                                        <td>{itemlist.total_items_sold}</td>

                                        <td>{itemlist.in_stock}</td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <></>
                              )}
                            </tbody>
                          </table>
                          {customersLowListMonthe.length ? (
                            ""
                          ) : (
                            <p
                              style={{
                                fontSize: "17px",
                                marginTop: "170px",
                              }}
                              className=" text-center justify-content-center align-items-center"
                            >
                              Oops! It doesn't look like there are any Top
                              Selling Products?
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default InventoryReports;
