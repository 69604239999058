// const PAYMENT_URL = "https://api.theanimalsapp.com:3000/api/paymentRoute/"

// =============================== LOCAL SERVER ================================ //
// export const BASE_URL = "http://192.168.1.36:3000/"; // VINOD IP
// =============================== LOCAL SERVER ================================ //

// =============================== DEVELOPMENT SERVER ================================ //
// export const BASE_URL = "https://animal-api.app.redbytes.in:3000/"; 
// =============================== DEVELOPMENT SERVER ================================ //

// =============================== PRODUCTION SERVER ================================ //
export const BASE_URL = "https://api.theanimalsapp.com:3000/"
// =============================== PRODUCTION SERVER ================================ //

const PAYMENT_ROUTE = "api/paymentRoute/"
const PHARMACY_ROUTE = "api/pharmacy/"

const ApiConfig = {
 
  //  ============= REGISTER API =============================
  REGISTER_USER: BASE_URL + PHARMACY_ROUTE + "registration",
  GET_OTP: BASE_URL + PHARMACY_ROUTE + "sendOtp",
  LOGIN_USER: BASE_URL + PHARMACY_ROUTE + "login",
  ADD_BANK_DETAILS: BASE_URL + PHARMACY_ROUTE + "addBank",
  ADD_SHOP_DETAILS: BASE_URL + PHARMACY_ROUTE + "addPharmacyShop",
  SEND_OTP_FOR_LOGIN: BASE_URL + PHARMACY_ROUTE + `sendOtp?loginOTP=${true}`,
  LOGIN_WITH_OTP: BASE_URL + PHARMACY_ROUTE + "loginWithOTP",

  FORGOT_PASSWORD: BASE_URL + PHARMACY_ROUTE + "forgotPassword",
  RESET_PASSWORD: BASE_URL + PHARMACY_ROUTE + "resetPassword",
  //  ============= PROFILE API =============================
  GET_USER_PROFILE_DETAILS: BASE_URL + PHARMACY_ROUTE + "getProfileDetails/",
  GET_LIST_OF_LANGUAGAES: BASE_URL + PHARMACY_ROUTE + "profileLookUpData",
  UPDATE_PROFILE_IN_FORM_DATA: BASE_URL + PHARMACY_ROUTE + "updateProfile",
  DELETE_DOCS_WITH_ID: BASE_URL + PHARMACY_ROUTE + "deleteDocument/",
  ADD_EDIT_LANGUAGE: BASE_URL + PHARMACY_ROUTE + "addEditLanguage",
  CHANGE_PROFILE_PHOTO: BASE_URL + PHARMACY_ROUTE + "changeProfileImage",
  UPDATE_BANK_DETAILS: BASE_URL + PHARMACY_ROUTE + "updateBank",
  GET_PROFILE_SHOP_TYPE: BASE_URL + PHARMACY_ROUTE + "getPharmacyShopType",
  UPDATE_USER_STATUS: BASE_URL + PHARMACY_ROUTE + "changePharmacyStatus",
  Notification_LIST: BASE_URL + PHARMACY_ROUTE + "getNotification/",

  /// Dashbord v
  DASHBORD_LIST: BASE_URL + PHARMACY_ROUTE + "getDashboardData?pharmacy_shop_id=",

  // Inventory
  INVENTORY_PRODUCT_LIST: BASE_URL + PHARMACY_ROUTE + "getProductsList?pharmacy_shop_id=",
  INVENTORY_PRODUCT_CATEGORYLIST:
    BASE_URL + PHARMACY_ROUTE + "getCategoryList?pharmacy_shop_id=",
  ADD_INVENTORY_DROPDOWN: BASE_URL + PHARMACY_ROUTE + "getCategoryLookUpData",
  ADD_PRODUCT_DROPDOWN: BASE_URL + PHARMACY_ROUTE + "getProductLookUpData",
  ADD_PRODUCT: BASE_URL + PHARMACY_ROUTE + "addProduct",
  EDIT_BYE_DETAILS: BASE_URL + PHARMACY_ROUTE + "pharmacyShopProduct/",
  PRODUCT_EDIT: BASE_URL + PHARMACY_ROUTE + "editProduct",
  ENABLE_DISABLED: BASE_URL + PHARMACY_ROUTE + "disable-product",

  //Order
  ORDER_DETALIS: BASE_URL + PHARMACY_ROUTE + "orders?pharmacy_shop_id=",
  ORDER_DETALIS_BY_ID: BASE_URL + PHARMACY_ROUTE + "orders/",
  ORDER_BY_VISTE: BASE_URL + PHARMACY_ROUTE + "getVisits",
  GET_STATUS_DROPDOWN: BASE_URL + PHARMACY_ROUTE + "getOrderStatusLookUpData?orderId=",
  UPDATE_STATUS: BASE_URL + PHARMACY_ROUTE + "updateOrderStatus",

  /// Customer

  CUSTOMER_LIST: BASE_URL + PHARMACY_ROUTE + "customers?pharmacy_shop_id=",
  CUSTOMER_DETALIS_BY_ID: BASE_URL + PHARMACY_ROUTE + "customers/",

  /// SELES
  SELES_DALIY_REPORT: BASE_URL + PHARMACY_ROUTE + "dailySalesReport",
  SELES_MONTHE_REPOT: BASE_URL + PHARMACY_ROUTE + "monthlySalesReport",
  FETCH_PAYMENT_ANALYTICS: BASE_URL + PHARMACY_ROUTE + "paymentAnalysis",
  // Inventory
  INVENTORY_REPORT_DAILT: BASE_URL + PHARMACY_ROUTE + "dailyInventoryReport",
  INVENTORY_MONTHE_REPORT: BASE_URL + PHARMACY_ROUTE + "monthlyInventoryReport",

  // Transaction
  TRANSACTION_RECENT_LIST: BASE_URL + PHARMACY_ROUTE + "getRecentTransactions?pharmacy_shop_id=",
  TRANSACTION_UPCOMING_LIST: BASE_URL + PHARMACY_ROUTE + "getUpcomingPayments/",

  // Subscription 
  GET_SUBSCRIPTION_PLANS: BASE_URL + PHARMACY_ROUTE + "subscriptions",
  REQUEST_SUBSCRIPTION: BASE_URL + PHARMACY_ROUTE + "requestSubscription",

  // Payment Withdraw api
  GET_PAYMENT_DROPDOWN: BASE_URL + PAYMENT_ROUTE + "bankAccounts",
  POST_WITHDRAW_REQUEST: BASE_URL + PAYMENT_ROUTE + "withdrawRequest",
};

export default ApiConfig;
